import React, { useState, useRef, useEffect } from 'react';
import './rangeInput.scss';
import gsap from 'gsap';

const RangeInput = props => {
  const containerRef = useRef();
  const inputMinRef = useRef();
  const inputMaxRef = useRef();
  const menuRef = useRef();

  const [inputValue, setInputValue] = useState([props.min, props.max]);
  const [animationDone, setAnimationDone] = useState(true);

  useEffect(() => {
    if (animationDone) {
      props.showed ? handleShow() : handleHide();
    } else {
      setTimeout(() => (props.showed ? handleShow() : handleHide()), 500);
    }
  }, [props.showed]);

  const handleShow = () => {
    setAnimationDone(false);
    gsap.set(menuRef.current, { display: 'block' });
    gsap
      .to(containerRef.current, { y: 0, ease: 'Power2.easeInOut' })
      .then(() => setAnimationDone(true));
    inputMinRef.current.focus();
  };

  const handleHide = () => {
    setAnimationDone(false);
    gsap
      .timeline()
      .to(containerRef.current, { y: '-100%', ease: 'Power2.easeInOut' })
      .set(menuRef.current, {
        display: 'none'
      })
      .then(() => setAnimationDone(true));
  };

  const handleInputKeyPress = e => {
    if (e.keyCode === 13) {
      setInputValue();
    } else if (e.keyCode === 40) {
    }
  };

  const handleChange = e => {
    if (e.target.name === 'min') {
      +e.target.value < inputValue[1] && setInputValue([+e.target.value, inputValue[1]]);
    } else {
      +e.target.value > inputValue[0] && setInputValue([inputValue[0], +e.target.value]);
    }
  };

  const handleTouchStart = e => {
    props.blockScroll(true);
  };

  const handleTouchEnd = e => {
    props.setValue(inputValue[0] + ' - ' + inputValue[1]);
    props.blockScroll(false);
  };

  return (
    <div
      className='range-input'
      ref={menuRef}
      onKeyDown={e => e.keyCode === 27 && props.setShowed(false)}
    >
      <div className='container' ref={containerRef}>
        <input
          key={props.min}
          name='min'
          value={inputValue[0]}
          onChange={handleChange}
          ref={inputMinRef}
          onKeyDown={handleInputKeyPress}
          type='range'
          min={props.min}
          max={props.max}
          step={props.step}
          onMouseDown={handleTouchStart}
          onTouchStart={handleTouchStart}
          onMouseUp={handleTouchEnd}
          onTouchEnd={handleTouchEnd}
        />
        <input
          key={props.max}
          name='max'
          value={inputValue[1]}
          onChange={handleChange}
          ref={inputMaxRef}
          onKeyDown={handleInputKeyPress}
          type='range'
          min={props.min}
          max={props.max}
          step={props.step}
          onMouseDown={handleTouchStart}
          onTouchStart={handleTouchStart}
          onMouseUp={handleTouchEnd}
          onTouchEnd={handleTouchEnd}
        />

        <span className='left'>{inputValue[0]}</span>
        <span className='right'>{inputValue[1]}</span>
      </div>
    </div>
  );
};

export default RangeInput;
