import Movie from '../../models/Movie';
import store from '../store';

export const getMovie = payload => {
  const filters = store.getState().filters.filters;
  const lastMovieId = store.getState().movies.movie?._id;
  return resolve => {
    resolve({
      type: 'MOVIE_GET_PENDING'
    });
    Movie.get(filters, lastMovieId)
      .then(res => {
        resolve({
          type: 'MOVIE_GET_FULFILLED',
          payload: res
        });
      })
      .catch(err => {
        resolve({
          type: 'MOVIE_GET_REJECTION',
          payload: err
        });
      });
  };
};

export const getMoviesById = payload => {
  return resolve => {
    resolve({
      type: `${payload.listName.toUpperCase()}_MOVIES_PENDING`,
      listName: payload.listName.toUpperCase()
    });
    Movie.getById(payload.id)
      .then(res => {
        resolve({
          type: `${payload.listName.toUpperCase()}_MOVIES_FULFILLED`,
          payload: res,
          listName: payload.listName.toUpperCase()
        });
      })
      .catch(err => {
        resolve({
          type: `${payload.listName.toUpperCase()}_MOVIES_REJECTION`,
          payload: err,
          listName: payload.listName.toUpperCase()
        });
      });
  };
};

export const getPopularMovies = payload => {
  return resolve => {
    resolve({ type: 'GET_POPULAR_PENDING' });
    Movie.getPopularMovies(payload.page, payload.perPage)
      .then(movies =>
        resolve({
          type: 'GET_POPULAR_FULFILLED',
          payload: movies
        })
      )
      .catch(err => {
        resolve({ type: 'GET_POPULAR_REJECTION' });
      });
  };
};

export const getTopRatedMovies = payload => {
  return resolve => {
    resolve({ type: 'GET_TOP_RATED_PENDING' });
    Movie.getTopRatedMovies(payload.page, payload.perPage)
      .then(movies =>
        resolve({
          type: 'GET_TOP_RATED_FULFILLED',
          payload: movies
        })
      )
      .catch(err => {
        resolve({ type: 'GET_TOP_RATED_REJECTION' });
      });
  };
};

export const getInTheater = payload => {
  return resolve => {
    resolve({ type: 'GET_IN_THEATER_PENDING' });
    Movie.getInTheater(payload.page, payload.perPage)
      .then(movies =>
        resolve({
          type: 'GET_IN_THEATER_FULFILLED',
          payload: movies
        })
      )
      .catch(err => {
        resolve({ type: 'GET_IN_THEATER_REJECTION' });
      });
  };
};

export const getUpcomingMovies = payload => {
  return resolve => {
    resolve({ type: 'GET_UPCOMING_PENDING' });
    Movie.getUpcomingMovies(payload.page, payload.perPage)
      .then(movies =>
        resolve({
          type: 'GET_UPCOMING_FULFILLED',
          payload: movies
        })
      )
      .catch(err => {
        resolve({ type: 'GET_UPCOMING_REJECTION' });
      });
  };
};
