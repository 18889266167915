import React, { useEffect, useRef } from 'react';
import './textPageTemplate.scss';
import gsap from 'gsap';
import { useDispatch } from 'react-redux';
import { filtersCollapse } from '../../store/filters/actions';

const TextPageTemplate = props => {
  const dispatch = useDispatch();
  const pageRef = useRef();
  const titleRef = useRef();

  useEffect(() => {
    dispatch(filtersCollapse());

    const typographyRefs = [...document.querySelectorAll('.typography')];
    const h3Refs = [];
    const pRefs = [];
    const svgRefs = [];
    typographyRefs.map(item => item.querySelector('h3') && h3Refs.push(item.querySelector('h3')));
    typographyRefs.map(item => item.querySelector('p') && pRefs.push(item.querySelector('p')));
    [...typographyRefs].map(typ =>
      [...typ.querySelectorAll('svg')].map(item => svgRefs.push(item))
    );

    gsap
      .timeline()
      .set(pageRef.current, {
        minHeight: '10rem',
        height: '10rem',
        padding: 0,
        marginBottom: '100vh',
        border: 'none'
      })
      .to('.bottom-blue', {
        height: '100vh',
        position: 'fixed',
        duration: 1.3,
        ease: 'Power2.easeInOut'
      })

      .from(pageRef.current, { width: 0, border: 'auto', duration: 0.8, ease: 'Power2.easeInOut' })
      .to(
        pageRef.current,
        {
          height: 'auto',
          padding: '10rem',
          minHeight: 'calc(100vh - 16rem)',
          marginBottom: 0,
          duration: 0.8,
          ease: 'Power2.easeInOut'
        },
        '+=0.1'
      )
      .set(pageRef.current, { clearProps: 'all' })
      .from(titleRef.current, { width: 0, paddingLeft: 0, paddingRight: 0 })
      .to('.load-div', { width: '100%', stagger: 0.1, duration: 0.8, ease: 'Power3.easeInOut' })
      .set(titleRef.current, { clearProps: 'all' })
      .set([...h3Refs, ...pRefs, ...svgRefs], {
        visibility: 'visible'
      })
      .to(
        '.load-div',
        { width: 0, marginLeft: '100%', stagger: 0.1, duration: 0.8, ease: 'Power3.easeInOut' },
        '+=0.3'
      );
  }, []);

  return (
    <div className='text-page-template' ref={pageRef}>
      <div className='title-holder' ref={titleRef}>
        <h1>{props.title}</h1>
      </div>
      {props.children}
    </div>
  );
};

export default TextPageTemplate;
