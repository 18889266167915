import gsap from 'gsap';

export default animate => {
  const preloaderRef = document.querySelector('.preloader');
  const logotype = document.querySelector('.preloader__logo-type');
  const logotypeWrapper = document.querySelector('.preloader__logo-type-wrapper');
  const wrapperWidth = window.getComputedStyle(logotypeWrapper).width;

  if (animate) {
    preloaderRef.querySelector('.preloader__svg-loader').remove();
    gsap
      .timeline()
      .set('.preloader__logo-wrapper', { display: 'flex' })
      .from('.preloader__movie-tape', { y: '100%', duration: 1.1, ease: 'Power3.easeInOut' })
      .from(logotype, { marginLeft: `-${wrapperWidth}`, duration: 1.1, ease: 'Power3.easeInOut' })
      .fromTo(
        logotypeWrapper,
        { width: 0 },
        { width: wrapperWidth, duration: 1.1, ease: 'Power3.easeInOut' },
        '-=1.1'
      )
      .then(tl => {
        setTimeout(() => {
          tl.timeScale(1.2)
            .reverse()
            .then(() => {
              gsap.to(preloaderRef, { height: 0, duration: 1.1, ease: 'Power3.easeInOut' });
            });
        }, 1000);
      });
  } else {
    preloaderRef.remove();
  }
};
