import React, { useState, useRef, useLayoutEffect } from 'react';
import './header.scss';
import { ReactComponent as Logo } from '../../assets/Logo.svg';
import { ReactComponent as UserIcon } from '../../assets/UserIcon.svg';
import { Link, useLocation, useHistory } from 'react-router-dom';
import DelayLink from '../../components/DelayLink/DelayLink';
import { connect, useDispatch } from 'react-redux';
import UserMenu from '../../components/userMenu/userMenu';
import Hamburger from '../../components/hamburger/hamburger';
import gsap from 'gsap';
import { logout } from '../../store/auth/login';
import UserMenuUl from './../../components/userMenu/userMenuUl';
import { pageInTransition } from './../../store/UI/actions';

const Header = props => {
  const [toggleUserMenu, setToggleUserMenu] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [userMenuLiStyle, setUserMenuLiStyle] = useState({});
  const menuRef = useRef();
  const userMenuLiRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const handleMenuToggle = () => {
    setToggleMenu(!toggleMenu);

    if (toggleMenu) {
      gsap.to(menuRef.current, {
        x: '100%',
        display: 'none',
        ease: 'Power2.easeInOut',
        duration: 0.8
      });
    } else {
      gsap.set(menuRef.current, {
        display: 'block'
      });

      setUserMenuLiStyle({
        minHeight:
          userMenuLiRef.current && window.innerHeight + 58 - userMenuLiRef.current.offsetTop
      });

      gsap.to(menuRef.current, {
        x: '0',
        display: 'block',
        ease: 'Power2.easeInOut',
        duration: 0.8
      });
    }
  };

  const handleLinkClick = e => {
    if (props.screen?.tabletLand && e.target.tagName === 'A') {
      handleMenuToggle();
    }
  };

  const handleListsOpen = e => {
    history.push('/' + e.target.getAttribute('name'));
    setToggleMenu(false);
  };

  const delayLinkProps = {
    delay: history.location.pathname === '/' ? 1300 : 0,
    onDelayStart: () => dispatch(pageInTransition(true)),
    onDelayEnd: () => dispatch(pageInTransition(false))
  };

  return (
    <header>
      <Link className='logo' to='/'>
        <Logo className='logo' />
      </Link>
      <nav>
        <ul ref={menuRef} onClick={handleLinkClick}>
          {/* {props.screen?.tabletLand && props.user && props.user._id ? (
            <UserIcon className='user-icon' />
          ) : ( */}
          {/* props.screen?.tabletLand && <div className='spacer' /> */}
          {/* )} */}
          {props.screen?.tabletLand && <div className='spacer' />}
          <li className={location.pathname === '/popular' ? 'active' : ''}>
            <DelayLink {...delayLinkProps} to='/popular'>
              Popular Movies
            </DelayLink>
          </li>
          <li className={location.pathname === '/topRated' ? 'active' : ''}>
            <DelayLink {...delayLinkProps} to='/topRated'>
              Top Rated
            </DelayLink>
          </li>
          <li className={location.pathname === '/upcoming' ? 'active' : ''}>
            <DelayLink {...delayLinkProps} to='/upcoming'>
              Upcoming
            </DelayLink>
          </li>
          <li className={location.pathname === '/inTheaters' ? 'active' : ''}>
            <DelayLink {...delayLinkProps} to='/inTheaters'>
              In Theaters
            </DelayLink>
          </li>
          {props.screen?.tabletLand && (
            <>
              <li className='accent-li'>
                {props.user?._id ? (
                  <Link to='#' onClick={() => dispatch(logout())}>
                    Log out
                  </Link>
                ) : (
                  <Link to='/login'>Log in</Link>
                )}
              </li>
              <div className='user-menu-phone' ref={userMenuLiRef} style={userMenuLiStyle}>
                {props.user._id && <UserMenuUl onClick={handleListsOpen} />}
              </div>
            </>
          )}
        </ul>
        <div className='user-container'>
          {props.user._id ? (
            <>
              <button
                className='button-primary'
                onClick={() => {
                  setToggleUserMenu(!toggleUserMenu);
                }}
              >
                <UserIcon className='icon' />
              </button>
              <UserMenu toggleMenu={toggleUserMenu} setToggleMenu={setToggleUserMenu} />
            </>
          ) : (
            <DelayLink to='/login' replace={false}>
              <button className='button-primary'>Log In</button>
            </DelayLink>
          )}
        </div>
      </nav>

      <Hamburger onClick={handleMenuToggle} toggleMenu={toggleMenu} />
    </header>
  );
};

const mapStateToProps = state => {
  return { user: state.auth.user, screen: state.UI?.screen && state.UI.screen };
};

export default connect(mapStateToProps)(Header);
