import Auth from '../../../models/Auth';

export const forgotPasswordRequest = email => {
  return dispatch => {
    dispatch({
      type: 'FORGET_PASSWORD_REQUEST_PENDING'
    });
    Auth.forgotPasswordRequest(email)
      .then(() =>
        dispatch({
          type: 'FORGET_PASSWORD_REQUEST_FULFILLED'
        })
      )
      .catch(() =>
        dispatch({
          type: 'FORGET_PASSWORD_REQUEST_REJECTION'
        })
      );
  };
};

export const forgotPasswordRequestCheck = token => {
  return dispatch => {
    dispatch({
      type: 'FORGET_PASSWORD_REQUEST_PENDING'
    });
    Auth.forgotPasswordRequestCheck(token)
      .then(res =>
        dispatch({
          type: 'FORGET_PASSWORD_REQUEST_FULFILLED',
          email: res.email
        })
      )
      .catch(() =>
        dispatch({
          type: 'FORGET_PASSWORD_REQUEST_REJECTION'
        })
      );
  };
};

export const createNewPassword = (history, email) => {
  history.replace('/createNewPassword');
  return {
    type: 'CREATE_NEW_PASSWORD',
    email: email
  };
};

export const createNewPasswordSuccess = () => {
  return {
    type: 'CREATE_NEW_PASSWORD_FULFILLED'
  };
};
