import React, { useRef, useEffect, useState } from 'react';
import './modal.scss';
import { ReactComponent as X } from '../../assets/X2.svg';
import gsap from 'gsap';
import DelayLink from '../DelayLink/DelayLink';
import * as fader from '../../utils/fader';
import { useDispatch } from 'react-redux';
import { modalShowed, modalClosed } from '../../store/UI/actions';

const Modal = props => {
  const modalRef = useRef();
  const [faderRef, setFaderRef] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.title) document.title = 'Movie Recommend - ' + props.title;

    const tl = gsap.timeline();
    if (modalRef.current) {
      tl.set(modalRef.current, { display: 'fixed' }).fromTo(
        modalRef.current,
        { opacity: 0, y: '-80%', duration: 0.6 },
        { opacity: 1, y: '-50%', duration: 0.6 }
      );
    }

    setFaderRef(fader.add(modalRef.current, 'beforebegin'));
    dispatch(modalShowed());

    return () => dispatch(modalClosed());
  }, [window.location.pathname]);

  const handleClose = back => {
    const tl = gsap.timeline();

    tl.to(modalRef.current, { opacity: 0, y: '-80%', duration: 0.6 })
      .set(modalRef.current, { clearProps: 'all' })
      .then(() => props.setTimeOut && props.setTimeOut(false));

    fader.remove(faderRef);

    if (window.history.length > 3) {
      !props.dontGoBack && back && tl.call(props.history.goBack, [], '-=0.3');
    } else {
      !props.dontGoBack && back && tl.call(props.history.push, ['/'], '-=0.3');
    }
  };

  return (
    <>
      <div className='modal' ref={modalRef}>
        <h3 className='title'>{props.title}</h3>
        {!props.dontGoBack ? (
          <DelayLink to='' delay={1300} onDelayStart={() => handleClose(true)} replace={false}>
            <X className='X' />
          </DelayLink>
        ) : (
          <X className='X' onClick={handleClose} />
        )}
        {React.cloneElement(props.children, { handleClose: handleClose })}
      </div>
    </>
  );
};

export default Modal;
