import Auth from '../../../models/Auth';

export const signUp = inputsValues => {
  return dispatch => {
    dispatch({ type: 'USER_PENDING' });
    Auth.signUp(inputsValues)
      .then(res => {
        localStorage.setItem('token', res.token);
        dispatch({ type: 'USER_FULFILLED', payload: res.user });
      })
      .catch(err => dispatch({ type: 'USER_REJECTION', payload: err }));
  };
};
