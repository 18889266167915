import React, { useEffect, useState } from 'react';
import './App.scss';
import { Switch, Route } from 'react-router-dom';
import Header from './layout/header/header';
import Filters from './layout/filters/filters';
import Home from './pages/home/home';
import Modal from './components/modal/modal';
import LoginForm from './components/loginForm/loginForm';
import SignupForm from './components/signupForm/signupForm';
import { useDispatch, connect } from 'react-redux';
import { checkAuth } from './store/auth/actions';
import store from './store/store';
import { saveStateToLocalStorage, loadStateFromLocalStorage } from './utils/localStore';
import { filtersInit } from './store/filters/actions';
import openInNewTab from './utils/openInNewTab';
import primeVideo from './assets/primeVideo.png';
import ListsModal from './components/listsModal/listsModal';
import GridPage from './pages/gridPage/gridPage';
import Banner from './components/banner/banner';
import preloader from './utils/preloader';
import Footer from './layout/footer/footer';
import ForgotPassword from './components/forgotPassword/forgotPassword';
import Auth from './models/Auth';
import CreateNewPasswordForm from './components/createNewPasswordForm/createNewPasswordForm';
import { createNewPassword } from './store/auth/forgotPassword/index';
import ContactForm from './components/contactForm/contactForm';
import FAQ from './pages/faq/faq';
import About from './pages/about/about';
import { toast, Slide } from 'react-toastify';
import useScreenSizeObserver from './hooks/useScreenSizeObserver';

const App = props => {
  const dispatch = useDispatch();
  const filtersInitState = loadStateFromLocalStorage();
  const [modalAdTime, setModalAdTime] = useState(false);
  const [modalAdTimeTo, setModalAdTimeTo] = useState(false);
  const [bannerAdTime, setBannerAdTime] = useState(false);
  const images = require.context('./assets/', true);

  let prevComponent = <Home />;

  useEffect(() => {
    dispatch(checkAuth());
    if (filtersInitState) {
      dispatch(filtersInit(filtersInitState));
    }
    preloader(window.history.length <= 2 ? true : false);
    // preloader(false);
  }, []);

  useScreenSizeObserver();

  toast.configure({
    autoClose: 5000,
    transition: Slide,
    type: 'Error',
    className: 'toastify-custom',
    bodyClassName: 'toastify-body-custom'
  });

  store.subscribe(() => {
    saveStateToLocalStorage({ filters: store.getState().filters });
  });

  const setPrevComponent = component => {
    prevComponent = component;
    return component;
  };

  useEffect(() => {
    setTimeout(() => {
      setModalAdTimeTo(true);
    }, 20000);

    setTimeout(() => {
      setBannerAdTime(true);
    }, 25000);
  }, []);

  useEffect(() => {
    if (!props.UI.modal && modalAdTimeTo) {
      setModalAdTime(true);
      setModalAdTimeTo(false);
    }
  }, [modalAdTimeTo, props.UI.modal]);

  return (
    <div className='App'>
      <Header />
      <Filters />
      <div
        className='background'
        style={{
          backgroundImage: `url(${
            props.screen?.phone ? images('./BackgroundMobile.jpg') : images('./Background.jpg')
          })`
        }}
      />
      <div className='background-backdrop' />
      <div className='bottom-blue' />
      <Switch>
        <Route
          path='/popular'
          render={props =>
            setPrevComponent(<GridPage {...props} name='popular' title='Popular movies' />)
          }
        />
        <Route
          path='/topRated'
          render={props =>
            setPrevComponent(<GridPage {...props} name='toprated' title='Top rated movies' />)
          }
        />
        <Route
          path='/upcoming'
          render={props =>
            setPrevComponent(<GridPage {...props} name='upcoming' title='Upcoming movies' />)
          }
        />
        <Route
          path='/inTheaters'
          render={props =>
            setPrevComponent(<GridPage {...props} name='intheaters' title='In theaters' />)
          }
        />

        <Route path='/faq' render={props => setPrevComponent(<FAQ {...props} />)} />

        <Route path='/about' render={props => setPrevComponent(<About {...props} />)} />

        <Route
          path='/favorite'
          render={props => (
            <>
              {prevComponent}
              <ListsModal {...props} name='favorite' title='Favorites' />
            </>
          )}
        />

        <Route
          path='/watchList'
          render={props => (
            <>
              {prevComponent}
              <ListsModal {...props} name='watchlist' title='Watch list' />
            </>
          )}
        />

        <Route
          path='/watched'
          render={props => (
            <>
              {prevComponent}
              <ListsModal {...props} name='watched' title='Watched' />
            </>
          )}
        />

        <Route
          path='/login'
          render={props => (
            <>
              {prevComponent ?? <Home />}
              <Modal {...props} title='Log In'>
                <LoginForm />
              </Modal>
            </>
          )}
        />

        <Route
          path='/forgotPassword/:token'
          render={props => {
            Auth.forgotPasswordRequestCheck(props.match.params.token)
              .then(res => dispatch(createNewPassword(props.history, res.email)))
              .catch(() => props.history.replace('/'));
          }}
        />

        {props.user.createNewPassword && (
          <Route
            path='/createNewPassword'
            render={props => (
              <>
                {prevComponent ?? <Home />}
                <Modal {...props} title='Create New Password'>
                  <CreateNewPasswordForm {...props} />
                </Modal>
              </>
            )}
          />
        )}

        <Route
          path='/forgotPassword'
          render={props => (
            <>
              {prevComponent ?? <Home />}
              <Modal {...props} title='Forgot Password'>
                <ForgotPassword />
              </Modal>
            </>
          )}
        />

        <Route
          path='/signup'
          render={props => (
            <>
              {prevComponent}
              <Modal {...props} title='Sign Up'>
                <SignupForm />
              </Modal>
            </>
          )}
        />

        <Route
          path='/contact'
          render={props => (
            <>
              {prevComponent ?? <Home />}
              <Modal {...props} title='Contact'>
                <ContactForm />
              </Modal>
            </>
          )}
        />

        <Route path='/' render={props => setPrevComponent(<Home {...props} />)} />
      </Switch>

      {modalAdTime && (
        <Modal title='' dontGoBack setTimeOut={setModalAdTime}>
          <>
            <p>Sign Up on Amazon Prime Video and watch movies for free</p>
            <img src={primeVideo} alt='Prime Video' />
            <button
              className='button-primary'
              onClick={() => openInNewTab('http://www.amazon.com/tryprimefree?tag=movierandom-20')}
            >
              Try Now
            </button>
          </>
        </Modal>
      )}

      {bannerAdTime && <Banner />}

      <Footer />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    screen: state.UI?.screen && state.UI.screen,
    UI: state.UI
  };
};

export default connect(mapStateToProps)(App);
