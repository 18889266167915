const initState = {
  loading: false,
  favorite: [],
  watchlist: [],
  watched: []
};

const userListsReducer = (state = initState, action) => {
  switch (action.type) {
    case `${action.listName}_MOVIES_PENDING`:
      return {
        ...state,
        loading: true
      };
    case `${action.listName}_MOVIES_FULFILLED`:
      return {
        ...state,
        [action.listName.toLowerCase()]: action.payload,
        loading: false
      };
    case `${action.listName}_MOVIES_REJECTION`:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default userListsReducer;
