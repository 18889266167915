import React, { useRef } from 'react';
import './userListsMovieHolder.scss';
import { ReactComponent as Star } from '../../assets/star.svg';
import api from '../../api.json';
import { ReactComponent as X } from '../../assets/X2.svg';
import { useDispatch } from 'react-redux';
import { addToList } from '../../store/auth/actions';
import gsap from 'gsap';

const UserListsMovieHolder = props => {
  const deleteDivRef = useRef();
  const infoRef = useRef();
  const deleteRef = useRef();
  const holderRef = useRef();
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(
      addToList({
        userId: props.userId,
        movieId: props.movie._id,
        add: false,
        listName: props.listName
      })
    );
  };

  const handleDeleteAnim = () => {
    const tl = gsap.timeline();

    tl.to(deleteRef.current, { opacity: 0, duration: 0.3 })
      .set(deleteRef.current, { visibility: 'hidden' })
      .to(
        deleteDivRef.current,
        {
          width: '100%',
          duration: 0.5,
          ease: 'Power4.easeIn'
        },
        0
      )
      .to(infoRef.current, { left: '-100%', duration: 0.9, ease: 'Power4.easeInOut' }, '+=0.1')
      .to(holderRef.current, { opacity: 0, ease: 'Power3.easeInOut' }, '-=0.8')
      .to(holderRef.current, { height: 0, duration: 1, ease: 'Power4.easeInOut' }, '-=1')
      .call(handleDelete);
  };

  return (
    <div className='lists-movie-holder' ref={holderRef}>
      <div
        className='poster'
        style={{
          backgroundImage: `url(${api.images}w500${props.movie.images.posters[0].file_path})`
        }}
      />
      <div className='info' ref={infoRef}>
        <h3 className='movie-title'>{props.movie.title}</h3>
        <span className='genres'>{props.movie.genre.join(', ')}</span>
        <div className='votes'>
          <Star className='star' />
          <span>{props.movie.vote_average}/10</span>
        </div>
        <div className='delete-div' ref={deleteDivRef} />
        <div className='unload-div' />
      </div>

      <X className='delete-from-list' onClick={handleDeleteAnim} ref={deleteRef} />
    </div>
  );
};

export default UserListsMovieHolder;
