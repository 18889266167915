const initState = {
  user: { loading: false }
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'USER_SET':
      return { user: { loading: false, ...action.payload } };
    case 'USER_REMOVE':
      return { user: { loading: false } };
    case 'USER_PENDING':
      return { user: { ...state.user, loading: true } };
    case 'USER_FULFILLED':
      return { user: { loading: false, ...action.payload } };
    case 'USER_REJECTION':
      return { user: { loading: false, ...action.payload } };
    case `${action.listName}_LIST_ADD`:
      return {
        ...state,
        user: {
          ...state.user,
          [action.listName]: [...state.user[action.listName], action.movieId]
        }
      };
    case `${action.listName}_LIST_REMOVE`:
      return {
        ...state,
        user: {
          ...state.user,
          [action.listName]: [...state.user[action.listName].filter(id => id !== action.movieId)]
        }
      };
    case 'CREATE_NEW_PASSWORD':
      return {
        ...state,
        user: {
          loading: false,
          createNewPassword: true,
          email: action.email
        }
      };
    case 'CREATE_NEW_PASSWORD_FULFILLED':
      return {
        ...state,
        user: {
          loading: false
        }
      };
    default:
      return state;
  }
};

export default authReducer;
