import Auth from '../../../models/Auth';

export const login = inputValues => {
  return dispatch => {
    dispatch({ type: 'USER_PENDING' });
    Auth.login(inputValues)
      .then(res => {
        localStorage.setItem('token', res.token);
        dispatch({ type: 'USER_FULFILLED', payload: res.user });
      })
      .catch(err => {
        dispatch({ type: 'USER_REJECTION', payload: err });
      });
  };
};

export const logout = () => {
  return dispatch => {
    localStorage.removeItem('token');
    dispatch({ type: 'USER_REMOVE' });
  };
};
