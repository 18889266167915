import React, { useState, useRef, useEffect } from 'react';
import './filterProperty.scss';
import GenreInput from '../genreInput/genreInput';
import Filter from '../filter/filter';
import ActorInput from '../actorInput/actorInput';
import RangeInput from './../rangeInput/rangeInput';
import { addFilter, addFilterOveride, removeFilter } from './../../store/filters/actions';
import { useDispatch, connect } from 'react-redux';

const FilterProperty = props => {
  const [toggleMenu, setToggleMenu] = useState(false);
  // const [filters, setFilters] = useState([]);
  const dispatch = useDispatch();

  const handleAddFilter = (filter, remove) => {
    if (!props.filters[props.name.toLowerCase()].includes(filter)) {
      if (!remove) {
        // setFilters([...filters, filter]);
        // props.setFilters({ ...props.filters, [props.name.toLowerCase()]: [...filters, filter] });

        dispatch(addFilter({ name: props.name.toLowerCase(), filter: filter }));
      } else {
        // setFilters([filter]);
        // props.setFilters({ ...props.filters, [props.name.toLowerCase()]: [filter] });

        dispatch(addFilterOveride({ name: props.name.toLowerCase(), filter: filter }));
      }
    }
  };

  const handleRemove = filter => {
    // setFilters(
    //   filters.filter(item => {
    //     return item !== filter;
    //   })
    // );

    dispatch(removeFilter({ name: props.name.toLowerCase(), filter: filter }));

    // props.setFilters({
    //   ...props.filters,
    //   [props.name.toLowerCase()]: filters.filter(item => {
    //     return item !== filter;
    //   })
    // });
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const ref = useRef();

  const handleClickOutside = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      setToggleMenu(false);
    }
  };

  return (
    <>
      <div className='filter-property' ref={ref}>
        <span onClick={() => setToggleMenu(!toggleMenu)}>{props.name}:</span>
        <div className='menu-button' onClick={() => setToggleMenu(!toggleMenu)}>
          <div className='line-1' />
          <div className='line-2' />
        </div>
        {props.type === 'input' ? (
          <ActorInput
            showed={toggleMenu}
            setValue={filter => handleAddFilter(filter)}
            setShowed={setToggleMenu}
            blockScroll={props.blockScroll}
          />
        ) : props.type === 'range' ? (
          <RangeInput
            showed={toggleMenu}
            setValue={filter => handleAddFilter(filter, true)}
            setShowed={setToggleMenu}
            min={props.min}
            max={props.max}
            step={props.step}
            blockScroll={props.blockScroll}
          />
        ) : (
          <GenreInput
            items={props.items}
            showed={toggleMenu}
            setValue={filter => handleAddFilter(filter)}
            setShowed={setToggleMenu}
            blockScroll={props.blockScroll}
          />
        )}
      </div>
      <div
        className='filters-container'
        style={{
          marginRight:
            props.screen?.phone && props.screen?.phone
              ? '3rem'
              : props.filters.length > 0
              ? '5rem'
              : '9rem'
        }}
      >
        {props.filters[props.name.toLowerCase()]?.map((filter, index) => {
          return <Filter key={filter} label={filter} remove={handleRemove} />;
        })}
      </div>
    </>
  );
};

const mapPropsToState = state => {
  return { filters: state.filters.filters, screen: state.UI.screen };
};

export default connect(mapPropsToState)(FilterProperty);
