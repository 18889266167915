import React, { useState, useEffect } from 'react';
import DelayLink from '../DelayLink/DelayLink';
import { useDispatch, connect } from 'react-redux';
import validate from '../../utils/validate';
import { createNewPasswordSuccess } from '../../store/auth/forgotPassword';
import Auth from '../../models/Auth';

const CreateNewPasswordForm = props => {
  const [inputsValues, setInputsValues] = useState({ password: '', passwordRepeat: '' });
  const dispatch = useDispatch();

  const handleSubmit = async e => {
    e.preventDefault();

    if (validate(inputsValues, 'newPassword')) {
      Auth.createNewPassword(inputsValues.password, props.user.email)
        .then(() => {
          dispatch(createNewPasswordSuccess());
          props.handleClose(false);
          setTimeout(() => props.history.replace('/login'), 1300);
        })
        .catch(() => {
          setInputsValues({ password: '', passwordRepeat: '' });
        });
    }
  };

  const handleOnChange = e => {
    setInputsValues({ ...inputsValues, [e.target.name]: e.target.value });
  };

  return (
    <form className='createNewPasswordForm'>
      <div className='input'>
        <input
          name='password'
          placeholder='Password'
          type='password'
          onChange={handleOnChange}
          value={inputsValues.password}
        />
        <div className='input-accent-line' />
      </div>
      <div className='input'>
        <input
          name='passwordRepeat'
          placeholder='Repeat password'
          type='password'
          onChange={handleOnChange}
          value={inputsValues.passwordRepeat}
        />
        <div className='input-accent-line' />
      </div>
      <button className='button-primary' onClick={handleSubmit}>
        Create
      </button>
      <span className='bottom-span'>Already created?</span>
      <DelayLink
        to='/login'
        delay={1300}
        onDelayStart={() => props.handleClose(false)}
        replace={true}
      >
        <span className='link'>Log In</span>
      </DelayLink>
    </form>
  );
};

const mapStateToProps = state => {
  return { user: state.auth.user };
};

export default connect(mapStateToProps)(CreateNewPasswordForm);
