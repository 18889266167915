import Axios from '../utils/axios';
import api from '../api.json';
import store from './../store/store';
import { toast } from 'react-toastify';

class Movie {
  get(filters, lastMovieId) {
    return new Promise((resolve, reject) => {
      const userId = store.getState().auth.user._id;
      Axios.get(api.getMovie, {
        params: { ...filters, lastMovieId: lastMovieId, userId: userId }
      })
        .then(res => resolve(res.data))
        .catch(err => {
          this.serverUnavailable();
          reject();
        });
    });
  }

  getById(id) {
    return new Promise((resolve, reject) => {
      Axios.get(api.getMoviesById, {
        params: { id: id }
      })
        .then(res => resolve(res.data))
        .catch(err => {
          this.serverUnavailable();
          reject();
        });
    });
  }

  getPopularMovies(page, perPage) {
    return new Promise((resolve, reject) => {
      Axios.get(api.getPopular, {
        params: {
          page: page,
          perPage: perPage
        }
      })
        .then(res => resolve(res.data))
        .catch(err => {
          this.serverUnavailable();
          reject();
        });
    });
  }

  getTopRatedMovies(page, perPage) {
    return new Promise((resolve, reject) => {
      Axios.get(api.getTopRated, {
        params: {
          page: page,
          perPage: perPage
        }
      })
        .then(res => resolve(res.data))
        .catch(err => {
          this.serverUnavailable();
          reject();
        });
    });
  }

  getInTheater(page, perPage) {
    return new Promise((resolve, reject) => {
      Axios.get(api.getInTheater, {
        params: {
          page: page,
          perPage: perPage
        }
      })
        .then(res => resolve(res.data))
        .catch(err => {
          this.serverUnavailable();
          reject();
        });
    });
  }

  getUpcomingMovies(page, perPage) {
    return new Promise((resolve, reject) => {
      Axios.get(api.getUpcoming, {
        params: {
          page: page,
          perPage: perPage
        }
      })
        .then(res => resolve(res.data))
        .catch(err => {
          this.serverUnavailable();
          reject();
        });
    });
  }

  serverUnavailable() {
    toast.info('Website under construction. Please try later.');
  }
}

export default new Movie();
