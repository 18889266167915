export const pageInTransition = is => {
  return {
    type: is ? 'PAGE_IN_TRANSITION_START' : 'PAGE_IN_TRANSITION_END'
  };
};

export const screenChange = screen => {
  return {
    type: 'SCREEN_CHANGE',
    screen: screen
  };
};

export const modalShowed = () => {
  return {
    type: 'MODAL_SHOWED'
  };
};

export const modalClosed = () => {
  return {
    type: 'MODAL_CLOSED'
  };
};
