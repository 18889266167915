import React, { useState, useRef, useEffect } from 'react';
import './question.scss';
import { ReactComponent as Arrow } from '../../assets/arrow-right.svg';
import gsap from 'gsap';
import { Link } from 'react-router-dom';

const Question = props => {
  const [toggle, setToggle] = useState(false);
  const answerRef = useRef();
  const answerHolderRef = useRef();
  const questionRef = useRef();
  const underlineRef = useRef();
  const arrowRef = useRef();

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, []);

  const handleOutsideClick = e => {
    if (!questionRef.current.contains(e.target)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    if (!toggle) {
      gsap
        .timeline()
        .to(answerHolderRef.current, { height: 0, ease: 'Power2.easeInOut', duration: 0.7 })
        .to(questionRef.current, { backgroundColor: 'transparent', duration: 0.6 }, 0)
        .to(arrowRef.current, { rotate: '90deg', duration: 0.6 }, 0)
        .to(underlineRef.current, { opacity: 1, duration: 0.2 }, '-=0.1')
        .set(
          [answerHolderRef.current, questionRef.current, underlineRef.current, arrowRef.current],
          { clearProps: 'height,backgroundColor,opacity,rotate' }
        );
    } else {
      gsap
        .timeline()
        .to(answerHolderRef.current, { height: 'auto', ease: 'Power2.easeInOut', duration: 0.7 })
        .to(questionRef.current, { backgroundColor: 'rgba(138, 138, 138, 0.2)', duration: 0.6 }, 0)
        .to(underlineRef.current, { opacity: 0, duration: 0.6 }, 0)
        .to(arrowRef.current, { rotate: '-90deg', duration: 0.6 }, 0);
    }
  }, [toggle]);

  return (
    <span className='question' onClick={() => setToggle(!toggle)} ref={questionRef}>
      <div className='question__holder'>
        <p>{props.question}</p>
        <Arrow className='question__arrow' ref={arrowRef} />
      </div>
      <div className='answer__holder' ref={answerHolderRef}>
        <span ref={answerRef}>{props.answer}</span>
        {props.link && (
          <a target='_blank' href={props.link}>
            {props.link}
          </a>
        )}
      </div>
      <div className='question__underline' ref={underlineRef}></div>
    </span>
  );
};

export default Question;
