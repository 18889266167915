import React, { useState, useEffect } from 'react';
import './loginForm.scss';
import DelayLink from '../DelayLink/DelayLink';
import { useDispatch, connect } from 'react-redux';
import { login } from '../../store/auth/actions';
import validate from '../../utils/validate';

const LoginForm = props => {
  const [inputsValues, setInputsValues] = useState({ email: '', password: '' });
  const dispatch = useDispatch();

  const handleSubmit = async e => {
    e.preventDefault();

    if (validate(inputsValues, 'login')) {
      dispatch(login(inputsValues));
    }
  };

  const handleOnChange = e => {
    setInputsValues({ ...inputsValues, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (props.user._id) {
      props.handleClose(true);
    }
  }, [props.user._id]);

  return (
    <form className='loginForm'>
      <div className='input'>
        <input
          name='email'
          placeholder='E-mail'
          onChange={handleOnChange}
          value={inputsValues.email}
        />
        <div className='input-accent-line' />
      </div>
      <div className='input'>
        <input
          name='password'
          placeholder='Password'
          type='password'
          onChange={handleOnChange}
          value={inputsValues.password}
        />
        <div className='input-accent-line' />
      </div>
      <DelayLink
        to='/forgotPassword'
        delay={1300}
        onDelayStart={() => props.handleClose(false)}
        replace={true}
        className='link-forgot'
      >
        Forgot password?
      </DelayLink>
      <button className='button-primary' onClick={handleSubmit}>
        Log In
      </button>
      <span className='bottom-span'>Don't have account?</span>
      <DelayLink
        to='/signup'
        delay={1300}
        onDelayStart={() => props.handleClose(false)}
        replace={true}
      >
        <span className='link'>Sign Up</span>
      </DelayLink>
    </form>
  );
};

const mapStateToProps = state => {
  return { user: state.auth.user };
};

export default connect(mapStateToProps)(LoginForm);
