export const calculateTime = time => {
  const calc = time / 60;
  const hours = Math.floor(calc);
  const minutes = Math.floor((calc - hours) * 60);
  return `${hours}h ${minutes}m`;
};

export const shortOverview = (overview, length) => {
  if (overview.length > length) {
    return overview.substr(0, length) + '...';
  } else {
    return overview;
  }
};
export const votesFormat = votes => {
  if (votes <= 999) {
    return votes;
  } else if (votes >= 1000) {
    return Math.round(votes / 1000) + 'k';
  }
};
export const writters = credits => {
  const res = [];
  credits.crew.map(item => {
    if (item.job === 'Writer' || item.job === 'Screenplay' || item.job === 'Story') {
      if (!res.includes(item.name)) {
        res.push(item.name);
      }
    }
  });

  if (res.length > 0) {
    return res;
  } else {
    return ['-'];
  }
};
