import React, { useState, useEffect, useRef } from 'react';
import './movieHolder.scss';
import { ReactComponent as Play } from '../../assets/play.svg';
import { ReactComponent as Loader } from '../../assets/loader.svg';
import api from '../../api.json';
import gsap from 'gsap';
import InfoForPoster from '../infoForPoster/infoForPoster';
import RepeatButton from '../repeatButton/repeatButton';
import MovieCard from '../movieCard/movieCard';
import { useDispatch, connect } from 'react-redux';
import { getMovie } from '../../store/movie/actions';
import { movieHolderTransform, movieHolderTransfromReverse } from '../../animations/movieHolder';

const MovieHolder = props => {
  const [posterStyle, setPosterStyle] = useState({});
  const [playStyle, setPlayStyle] = useState({});
  const [catcherStyle, setCatcherStyle] = useState({});
  const [cardExpand, setCardExpand] = useState(false);
  const [infoForPosterHide, setInfoForPosterHide] = useState(false);
  const [repeatShow, setRepeatShow] = useState(true);
  const [showCard, setShowCard] = useState(false);
  const [unloadDone, setUnloadDone] = useState(true);
  const [movie, setMovie] = useState({});

  const movieHolderRef = useRef();
  const clickCatcherRef = useRef();
  const playRef = useRef();
  const loaderRef = useRef();
  const posterRef = useRef();

  const dispatch = useDispatch();

  const loadMovieAnimation = () => {
    setInfoForPosterHide(false);
    setRepeatShow(true);

    gsap.set(playRef.current, { clearProps: 'display' });

    gsap.to(loaderRef.current, {
      opacity: 0,
      ease: 'Power2.easeInOut',
      duration: 0.3
    });

    gsap.set(clickCatcherRef.current, { clearProps: 'display' });
    gsap.to(clickCatcherRef.current, { opacity: 1 });
  };

  const loadPoster = () => {
    const poster = new Image();
    // const poster = `url(${api.images}${movie.images.posters[0].file_path})`;
    poster.src = api.images + 'w500' + props.movie.images.posters[0].file_path;

    poster.onload = () => {
      setPosterStyle({
        backgroundImage: `url(${poster.src})`
      });
    };
  };

  const loadBackdrop = load => {
    if (load) {
      const backdrop = new Image();
      backdrop.src = api.images + 'w1280' + props.movie.images.backdrops[0].file_path;

      backdrop.onload = () => {
        gsap.set('.background-backdrop', { backgroundImage: `url(${backdrop.src})` });
        gsap.to('.background-backdrop', { opacity: 0.6, duration: 2 });
        gsap.to('.background', { opacity: 0, duration: 2 });
      };
    } else {
      gsap.to('.background-backdrop', { opacity: 0, duration: 2 });
      gsap.to('.background', { opacity: 0.5, duration: 2 });
    }
  };

  const unloadMovieAnimation = () => {
    if (!props.movie._id) {
      setUnloadDone(true);
      setRepeatShow(false);
      return;
    }

    gsap.to(loaderRef.current, {
      opacity: 1,
      ease: 'Power2.easeInOut',
      duration: 0.3
    });

    const tl = gsap.timeline();

    tl.to(clickCatcherRef.current, { opacity: 0, duration: 0.3 })
      .to(playRef.current, { opacity: 0, duration: 0.3 }, '-=0.3')
      .set(clickCatcherRef.current, { display: 'none' }, '+=0.2')
      .set(playRef.current, { display: 'none' });

    setInfoForPosterHide(true);
    setRepeatShow(false);
  };

  const handleGetMovie = async () => {
    if (cardExpand) {
      return;
    }
    unloadMovieAnimation();

    dispatch(getMovie());
  };

  useEffect(() => {
    if (unloadDone && !props.movie.loading && props.movie._id) {
      setMovie(props.movie);
      !cardExpand && loadMovieAnimation();
      setUnloadDone(false);
      loadPoster();
    }
  }, [props.movie.loading, unloadDone]);

  const handleTransformation = e => {
    setCardExpand(true);

    movieHolderTransform(
      clickCatcherRef,
      setRepeatShow,
      dispatch,
      setPlayStyle,
      setShowCard,
      movieHolderRef,
      setInfoForPosterHide,
      loadBackdrop
    );
  };

  const handleTransformationReverse = () => {
    movieHolderTransfromReverse(
      movieHolderRef,
      dispatch,
      setRepeatShow,
      setInfoForPosterHide,
      clickCatcherRef,
      playRef,
      setCardExpand,
      loadBackdrop
    );
  };

  useEffect(() => {
    if (!showCard && cardExpand) {
      handleTransformationReverse();
    }
  }, [showCard]);

  const handleHover = e => {
    if (e.type === 'mouseenter') {
      setCatcherStyle({ boxShadow: '0 0 15rem rgba(0, 0, 0, 0.897) inset' });
      setPlayStyle({ opacity: !cardExpand && 1 });
    } else {
      setCatcherStyle({ boxShadow: '0 0 15rem rgba(0, 0, 0, 0) inset' });
      setPlayStyle({ opacity: 0 });
    }
  };

  useEffect(() => {
    gsap.to(movieHolderRef.current, { zIndex: 1 });
  });

  return (
    <>
      <div
        className='movie-holder'
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
        ref={movieHolderRef}
      >
        <div className='poster' style={posterStyle} ref={posterRef} />
        {movie._id && (
          <>
            <Loader className='loader' ref={loaderRef} />

            <InfoForPoster movie={movie} setUnloadDone={setUnloadDone} hide={infoForPosterHide} />

            <Play className='play' style={playStyle} ref={playRef} />

            <div
              className='click-catcher'
              onClick={handleTransformation}
              ref={clickCatcherRef}
              style={catcherStyle}
            />

            <MovieCard movie={movie} show={showCard} setShow={setShowCard} posterRef={posterRef} />
          </>
        )}

        <RepeatButton movie={movie._id && true} onClick={handleGetMovie} show={repeatShow} />
      </div>
      {/* <div className='icon-scroll'></div> */}
    </>
  );
};

const mapStateToProps = state => {
  return { movie: state.movies.movie };
};

export default connect(mapStateToProps)(MovieHolder);
