const initState = {
  movie: { loading: false },
  popular: { movies: [], loading: false },
  toprated: { movies: [], loading: false },
  upcoming: { movies: [], loading: false },
  intheaters: { movies: [], loading: false }
};

const movieReducer = (state = initState, action) => {
  switch (action.type) {
    case 'MOVIE_GET_PENDING':
      return {
        ...state,
        movie: { ...state.movie, loading: true }
      };
    case 'MOVIE_GET_FULFILLED':
      if (action.payload) {
        return {
          ...state,
          movie: {
            ...action.payload,
            loading: false
          }
        };
      } else {
        return { ...state, movie: { ...state.movie, loading: false } };
      }
    case 'MOVIE_GET_REJECTION':
      return {
        ...state,
        movie: { ...state.movie, loading: false }
      };

    case 'GET_POPULAR_PENDING':
      return {
        ...state,
        popular: { ...state.popular, loading: true }
      };
    case 'GET_POPULAR_FULFILLED':
      return {
        ...state,
        popular: { movies: [...state.popular.movies, ...action.payload], loading: false }
      };
    case 'GET_POPULAR_REJECTION':
      return {
        ...state,
        popular: { ...state.popular, loading: false }
      };

    case 'GET_TOP_RATED_PENDING':
      return {
        ...state,
        toprated: { ...state.toprated, loading: true }
      };
    case 'GET_TOP_RATED_FULFILLED':
      return {
        ...state,
        toprated: { movies: [...state.toprated.movies, ...action.payload], loading: false }
      };
    case 'GET_TOP_RATED_REJECTION':
      return {
        ...state,
        toprated: { ...state.toprated, loading: false }
      };

    case 'GET_IN_THEATER_PENDING':
      return {
        ...state,
        intheaters: { ...state.intheaters, loading: true }
      };
    case 'GET_IN_THEATER_FULFILLED':
      return {
        ...state,
        intheaters: { movies: [...state.intheaters.movies, ...action.payload], loading: false }
      };
    case 'GET_IN_THEATER_REJECTION':
      return {
        ...state,
        intheaters: { ...state.intheaters, loading: false }
      };

    case 'GET_UPCOMING_PENDING':
      return {
        ...state,
        intheaters: { ...state.intheaters, loading: true }
      };
    case 'GET_UPCOMING_FULFILLED':
      return {
        ...state,
        upcoming: { movies: [...state.upcoming.movies, ...action.payload], loading: false }
      };
    case 'GET_UPCOMING_REJECTION':
      return {
        ...state,
        upcoming: { ...state.upcoming, loading: false }
      };
    default:
      return state;
  }
};

export default movieReducer;
