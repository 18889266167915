import { combineReducers } from 'redux';
import authReducer from './auth/reducer';
import filtersReducer from './filters/reducer';
import movieReducer from './movie/reducer';
import userListsReducer from './movie/userListsReducer';
import uiReducer from './UI/reducer';
import indexReducer from './reducer';

export default combineReducers({
  auth: authReducer,
  filters: filtersReducer,
  movies: movieReducer,
  userLists: userListsReducer,
  UI: uiReducer
});
