import Axios from '../utils/axios';
import api from '../api.json';
import { toast } from 'react-toastify';

class Auth {
  login(inputsValues) {
    return new Promise((resolve, reject) =>
      Axios.post(api.login, inputsValues)
        .then(res => {
          if (res.status === 200) {
            localStorage.setItem('token', res.data.token);
            toast.success(res.data.message ?? null);
            resolve(res.data);
          } else {
            resolve();
          }
        })
        .catch(err => {
          toast.error(err.data.message);
          reject();
        })
    );
  }

  logout() {
    localStorage.removeItem('token');
    toast.success('You are logged out');
  }

  signUp(inputsValues) {
    return new Promise((resolve, reject) =>
      Axios.post(api.signUp, inputsValues)
        .then(res => {
          localStorage.setItem('token', res.data.token);
          toast.success(res.data.message);
          resolve(res.data);
        })
        .catch(err => {
          toast.error('Invalid inputs');
          reject();
        })
    );
  }

  authCheck() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token');
      if (token) {
        Axios.get(api.authCheck, {
          headers: { Authorization: 'Barer ' + token }
        })
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject();
          });
      } else {
        resolve();
      }
    });
  }

  updateList(userId, movieId, add, listName) {
    return new Promise((resolve, reject) => {
      Axios.post(api.listUpdate, {
        userId: userId,
        movieId: movieId,
        add: add,
        listName: listName
      })
        .then(res => {
          toast.success(res.data.message);
          resolve({ movieId, add, listName });
        })
        .catch(err => {
          toast.error(err.data.message);
          reject();
        });
    });
  }

  forgotPasswordRequest(email) {
    return new Promise((resolve, reject) => {
      Axios.post(api.forgotPasswordRequest, { email: email })
        .then(res => {
          toast.success(res.data.message);
          resolve(res.data);
        })
        .catch(err => {
          toast.error(err.data.message);
          reject();
        });
    });
  }

  forgotPasswordRequestCheck(token) {
    return new Promise((resolve, reject) => {
      Axios(api.forgotPasswordRequestCheck, { params: { token: token } })
        .then(res => resolve(res.data))
        .catch(err => {
          toast.error(err.data.message);
          reject();
        });
    });
  }

  createNewPassword(password, email) {
    return new Promise((resolve, reject) => {
      Axios.post(api.createNewPassword, { password, email })
        .then(res => {
          toast.success(res.data.message);
          resolve(res.data);
        })
        .catch(err => {
          toast.error(err.data.message);
          reject();
        });
    });
  }
}

export default new Auth();
