import React, { useEffect } from 'react';
import './faq.scss';
import gsap from 'gsap';
import Question from '../../components/question/question';
import questions from '../../questions.json';
import TextPageTemplate from './../textPageTemplate/textPageTemplate';
import { Helmet } from 'react-helmet';

const FAQ = () => {
  useEffect(() => {
    gsap.timeline().fromTo(
      '.question',
      { opacity: 0, y: '-60%' },
      {
        display: 'block',
        visibility: 'visible',
        opacity: 1,
        stagger: 0.1,
        ease: 'Power2.easeOut',
        duration: 1,
        y: '0%'
      },
      '+=3.5'
    );
  }, []);

  return (
    <TextPageTemplate title='Frequently Asked Questions'>
      <Helmet>
        <title>Movie Recommend - FAQ</title>
        <meta name='description' content='Frequently asked questions about Movie Recommend' />
      </Helmet>
      {questions.map(item => (
        <Question question={item.question} answer={item.answer} link={item.link && item.link} />
      ))}
    </TextPageTemplate>
  );
};

export default FAQ;
