import Auth from '../../models/Auth';

export const checkAuth = () => {
  return dispatch => {
    Auth.authCheck().then(res => {
      dispatch({ type: 'USER_SET', payload: res });
    });
  };
};

export const addToList = payload => {
  return resolve => {
    Auth.updateList(payload.userId, payload.movieId, payload.add, payload.listName).then(res => {
      resolve({
        type: `${payload.listName}_LIST_${payload.add ? 'ADD' : 'REMOVE'}`,
        movieId: payload.movieId,
        listName: payload.listName,
        add: payload.add
      });
    });
  };
};

export { login, logout } from './login';
export { signUp } from './signUp';
export {} from './forgotPassword';
