import React from 'react';
import './iconHolder.scss';

const IconHolder = props => {
  return (
    <div className='icon'>
      {props.children}{' '}
      <div className='tooltip'>
        <span>{props.tooltip}</span>
      </div>
    </div>
  );
};

export default IconHolder;
