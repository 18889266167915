import React from 'react';
import './footer.scss';
import { ReactComponent as Logo } from '../../assets/Logo.svg';
import { Link, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { logout } from '../../store/auth/login';
import DelayLink from '../../components/DelayLink/DelayLink';
import { pageInTransition } from './../../store/UI/actions';
import { ReactComponent as Arrow } from '../../assets/arrow-right-suggest.svg';

const Footer = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const delayLinkProps = {
    delay: history.location.pathname === '/' ? 1300 : 0,
    onDelayStart: () => {
      dispatch(pageInTransition(true));
      scrollToTop();
    },
    onDelayEnd: () => dispatch(pageInTransition(false))
  };

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer>
      <Link to='/' className='logo-link' onClick={scrollToTop}>
        <Logo className='logo' />
      </Link>

      <ul>
        <li>Movies</li>
        <li>
          <DelayLink {...delayLinkProps} to='/popular'>
            Popular
          </DelayLink>
        </li>
        <li>
          <DelayLink {...delayLinkProps} to='/topRated'>
            Top Rated
          </DelayLink>
        </li>
        <li>
          <DelayLink {...delayLinkProps} to='/upcoming'>
            Upcoming
          </DelayLink>
        </li>
        <li>
          <DelayLink {...delayLinkProps} to='/inTheaters'>
            In Theaters
          </DelayLink>
        </li>
      </ul>

      <ul>
        <li>Help</li>
        <li>
          <Link to='/faq' onClick={scrollToTop}>
            FAQ
          </Link>
        </li>
        <li>
          <Link to='/contact' onClick={scrollToTop}>
            Contact
          </Link>
        </li>
        <li>
          <Link to='/about' onClick={scrollToTop}>
            About
          </Link>
        </li>
      </ul>

      <ul>
        <li>Profile</li>

        {props.auth ? (
          <>
            <li>
              <a href='#' onClick={() => dispatch(logout())}>
                Log Out
              </a>
            </li>
            <li>
              <Link to='/watched'>Watched</Link>
            </li>
            <li>
              <Link to='/favorite'>Favorite</Link>
            </li>
            <li>
              <Link to='/watchList'>Watch List</Link>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link to='/logIn'>Log In</Link>
            </li>
            <li>
              <Link to='/signup'>Sign Up</Link>
            </li>
            <li>
              <Link to='/forgotPassword'>Forget password?</Link>
            </li>
          </>
        )}
      </ul>

      <div className='suggestions'>
        <span className='suggestions__suggest'>
          Have a suggestion how to improve Movie Recommend?
        </span>
        <Link to='/contact'>
          <span className='suggestions__let-us-know'>Let us know</span>
          <Arrow className='suggestions__arrow' />
        </Link>
      </div>

      <span className='copyrights'>© {new Date().getFullYear()} All Rights Reserved.</span>
    </footer>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth.user?._id ? true : false
  };
};

export default connect(mapStateToProps)(Footer);
