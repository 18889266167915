const initState = {
  isOpen: true,
  filters: {
    genre: [],
    year: [],
    rating: [],
    cast: []
  }
};

const filtersReducer = (state = initState, action) => {
  switch (action.type) {
    case 'FILTER_ADD':
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: [...state.filters[action.payload.name], action.payload.filter]
        }
      };
    case 'FILTER_ADD_OVERIDE': {
      return {
        ...state,
        filters: { ...state.filters, [action.payload.name]: [action.payload.filter] }
      };
    }
    case 'FILTER_REMOVE':
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: [
            ...state.filters[action.payload.name].filter(item => {
              return item !== action.payload.filter;
            })
          ]
        }
      };

    case 'FILTERS_COLLAPSE':
      return {
        ...state,
        isOpen: false
      };
    case 'FILTERS_OPEN':
      return {
        ...state,
        isOpen: true
      };
    case 'FILTERS_INIT':
      return {
        ...state,
        filters: { ...action.payload.filters.filters }
      };
    default:
      return state;
  }
};

export default filtersReducer;
