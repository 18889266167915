import { useEffect } from 'react';
import { isTablet, isTabletPort, isPhone } from './../utils/screens';
import { screenChange } from '../store/UI/actions';
import store from '../store/store';
import isEquivalent from './../utils/isEquivalent';

const useScreenSizeObserver = () => {
  let screen = { tabletLand: false, tabletPort: false, phone: false };
  const isChange = () => {
    const scr = { tabletLand: isTablet(), tabletPort: isTabletPort(), phone: isPhone() };
    if (!isEquivalent(screen, scr)) {
      store.dispatch(screenChange(scr));
      screen = scr;
    }
  };
  useEffect(() => {
    isChange();
    window.addEventListener('resize', isChange);
    return () => window.removeEventListener('resize', isChange);
  }, []);
};

export default useScreenSizeObserver;
