import React, { useState } from 'react';
import { connect } from 'react-redux';
import validate from '../../utils/validate';
import email from './../../utils/email';

const ContactForm = props => {
  const inputsInitState = { name: '', email: '', message: '' };
  const [inputsValues, setInputsValues] = useState(inputsInitState);

  const handleSubmit = async e => {
    e.preventDefault();

    if (validate(inputsValues, 'contact')) {
      email({ ...inputsValues, userId: props.user?._id })
        .then(() => {
          props.handleClose(true);
        })
        .catch(() => {
          /*setInputsValues(inputsInitState)*/
        });
    }
  };

  const handleOnChange = e => {
    setInputsValues({ ...inputsValues, [e.target.name]: e.target.value });
  };

  return (
    <form className='contactForm'>
      <div className='input'>
        <input
          name='name'
          placeholder='Full Name'
          type='text'
          onChange={handleOnChange}
          value={inputsValues.name}
        />
        <div className='input-accent-line' />
      </div>
      <div className='input'>
        <input
          name='email'
          placeholder='E-mail'
          onChange={handleOnChange}
          value={inputsValues.email}
        />
        <div className='input-accent-line' />
      </div>
      <div className='input'>
        <input
          name='message'
          placeholder='Message'
          onChange={handleOnChange}
          value={inputsValues.message}
        />
        <div className='input-accent-line' />
      </div>
      <button className='button-primary' onClick={handleSubmit}>
        Send
      </button>
    </form>
  );
};

const mapStateToProps = state => {
  return { user: state.auth.user };
};

export default connect(mapStateToProps)(ContactForm);
