import React, { useRef, useEffect, useState } from 'react';
import './listsModal.scss';
import { ReactComponent as X } from '../../assets/X2.svg';
import gsap from 'gsap';
import DelayLink from '../DelayLink/DelayLink';
import { connect, useDispatch } from 'react-redux';
import { getMoviesById } from '../../store/movie/actions';
import UserListsMovieHolder from './../userListsMovieHolder/userListsMovieHolder';
import { ReactComponent as Arrow } from '../../assets/arrow-right.svg';
import * as fader from '../../utils/fader';
import { modalShowed, modalClosed } from '../../store/UI/actions';

const ListsModal = props => {
  const modalRef = useRef();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [movies, setMovies] = useState([]);
  const [faderRef, setFaderRef] = useState(null);

  useEffect(() => {
    const tl = gsap.timeline();

    setFaderRef(fader.add(modalRef.current, 'beforebegin'));

    tl.set(modalRef.current, { display: 'flex' }).fromTo(
      modalRef.current,
      { opacity: 0, y: -120, duration: 0.6 },
      { opacity: 1, y: 0, duration: 0.6 },
      '+=0.2'
    );

    dispatch(modalShowed());
  }, []);

  useEffect(() => {
    if (props.user?._id && props.userLists[props.name].length === 0) {
      const ids = props.user[props.name];
      dispatch(getMoviesById({ id: ids, listName: props.name }));
    }
  }, [props.user]);

  const handleClose = back => {
    const tl = gsap.timeline();

    fader.remove(faderRef);

    tl.to(
      modalRef.current,
      {
        opacity: 0,
        y: -120,
        duration: 0.6,
        ease: 'ease'
      },
      '+=0.2'
    ).set(modalRef.current, { display: 'none' });

    if (props.history.length > 3) {
      back && tl.call(props.history.goBack, [], '-=0.3');
    } else {
      back && tl.call(props.history.push, ['/'], '-=0.3');
    }

    dispatch(modalClosed());
  };

  useEffect(() => {
    if (props.userLists[props.name].length > 3) {
      setPages(Math.ceil(props.userLists[props.name].length / 3 - 1));
      handlePagination();
    } else if (props.userLists[props.name].length > 0) {
      setMovies(props.userLists[props.name]);
    }
  }, [
    props.userLists[props.name],
    props.userLists[props.name].length == 0 && props.userLists.loading
  ]);

  useEffect(() => {
    if (movies) {
      moviesLoadAnimation();
    }
  }, [movies]);

  const handlePagination = () => {
    const movies = [];

    for (let i = page * 3; i < page * 3 + 3; i++) {
      if (props.userLists[props.name][i]) {
        movies.push(props.userLists[props.name][i]);
      }
    }

    setMovies(movies);
  };

  const handlePrevPage = () => {
    if (page - 1 >= 0) {
      moviesUnloadAnimation().then(() => {
        setPage(page - 1);
      });
    }
  };

  const handleNextPage = () => {
    if (page + 1 <= pages) {
      moviesUnloadAnimation().then(() => {
        setPage(page + 1);
      });
    }
  };

  useEffect(() => {
    if (page >= 0) {
      handlePagination();
    }
  }, [page]);

  const moviesUnloadAnimation = () => {
    const tl = gsap.timeline();
    const refs = document.querySelectorAll('.lists-movie-holder');
    const unloadDiv = [...refs].map(ref => ref.querySelector('.unload-div'));
    const infoDiv = [...refs].map(ref => ref.querySelector('.info'));

    return new Promise(resolve => {
      tl.to(unloadDiv, {
        width: '100%',
        stagger: 0.1,
        duration: 0.5,
        ease: 'Power4.easeIn'
      })
        .to(infoDiv, {
          left: '-100%',
          stagger: 0.1,
          duration: 0.9,
          ease: 'Power4.easeInOut'
        })
        .to(refs, { opacity: 0, stagger: 0.1, duration: 0.5, ease: 'Power2.easeIn' }, '-=1.1')
        .then(() => resolve());
    });
  };

  const moviesLoadAnimation = () => {
    const tl = gsap.timeline();
    const refs = document.querySelectorAll('.lists-movie-holder');
    const unloadDiv = [...refs].map(ref => ref.querySelector('.unload-div'));
    const infoDiv = [...refs].map(ref => ref.querySelector('.info'));
    const deleteRef = [...refs].map(ref => ref.querySelector('.delete-from-list'));

    tl.set(refs, { visibility: 'visible' })
      .set(deleteRef, { visibility: 'hidden' })
      .set([...refs, ...unloadDiv, ...infoDiv], { transition: 'none' })
      .set([...refs], { scale: 1 })
      .from(refs, { opacity: 0, stagger: 0.1, duration: 0.5, ease: 'Power2.easeIn' })
      .from(
        infoDiv,
        {
          left: '-100%',
          stagger: 0.1,
          duration: 0.9,
          ease: 'Power4.easeInOut'
        },
        '-=0.5'
      )
      .from(unloadDiv, {
        width: '100%',
        stagger: 0.1,
        duration: 0.5,
        ease: 'Power4.easeIn'
      })
      .set([...refs, ...unloadDiv, ...infoDiv], { clearProps: 'transition' })
      .set(deleteRef, { visibility: 'visible' })
      .set([...refs], { clearProps: 'scale' });
  };

  return (
    <>
      <div className='lists-modal' ref={modalRef}>
        <div className='top'>
          <h3 className='title'>{props.title}</h3>
          <DelayLink to='' delay={1300} onDelayStart={() => handleClose(true)} replace={false}>
            <X className='X' />
          </DelayLink>
        </div>

        {movies.length > 0 ? (
          movies.map(movie => {
            return (
              <UserListsMovieHolder
                movie={movie}
                key={movie._id}
                listName={props.name}
                userId={props.user._id}
              />
            );
          })
        ) : (
          <span className='no-movies'>You dont have any movies marked</span>
        )}
        <div className='pagination-place-keeper'></div>
        <div className='pagination'>
          <Arrow className='arrow__left' onClick={handlePrevPage} />
          <Arrow className='arrow__right' onClick={handleNextPage} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    userLists: state.userLists
  };
};

export default connect(mapStateToProps)(ListsModal);
