export const withAspectRatio = (size, aspect) => {
  return size * aspect;
};

export const precentOfSize = (precent, size) => {
  return (precent * size) / 100;
};

export const biggerByPrecentInAspect = (width, precent, aspect) => {
  const newWidth = precentOfSize(100 + precent, width);
  return {
    width: newWidth,
    height: withAspectRatio(newWidth, aspect)
  };
};
