export const saveStateToLocalStorage = state => {
  localStorage.setItem('store', JSON.stringify(state));
};

export const loadStateFromLocalStorage = () => {
  const store = localStorage.getItem('store');
  if (store) {
    return JSON.parse(store);
  }
};
