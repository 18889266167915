import gsap from 'gsap';

export const add = (element, position, top) => {
  const fader = document.createElement('div');
  const id = document.querySelectorAll('.fader').length;
  const elementStyle = window.getComputedStyle(element);

  fader.className = 'fader';
  fader.id = `fader-${id}`;
  fader.style.width = '100%';
  fader.style.height = '100vh';
  fader.style.position = 'fixed';
  fader.style.top = top ?? 0;
  fader.style.left = 0;
  fader.style.backgroundColor = 'black';
  fader.style.display = 'none';
  fader.style.zIndex = parseInt(elementStyle.zIndex) - 1;

  const faderRef = element.insertAdjacentElement(position, fader);
  gsap
    .timeline()
    .set(faderRef, { display: 'block' })
    .fromTo(faderRef, { opacity: 0 }, { opacity: 0.5, duration: 0.8 });

  return faderRef;
};

export const remove = faderRef => {
  gsap.to(faderRef, { opacity: 0, duration: 1.2 }).then(() => {
    faderRef.remove();
  });
};
