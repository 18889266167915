import Axios from 'axios';
import api from '../api.json';
import { toast, Slide } from 'react-toastify';

Axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error.response);
  }
);

toast.configure({
  autoClose: 5000,
  transition: Slide,
  type: 'Error',
  className: 'toastify-custom',
  bodyClassName: 'toastify-body-custom'
});

const email = data => {
  return new Promise((resolve, reject) => {
    Axios.post(api.contact, { params: { ...data } })
      .then(res => {
        toast.success('Successfully sent email');
        resolve();
      })
      .catch(err => {
        toast.error('Invalid inputs');
        reject();
      });
  });
};

export default email;
