export const addFilter = payload => {
  return {
    type: 'FILTER_ADD',
    payload: payload
  };
};

export const addFilterOveride = payload => {
  return {
    type: 'FILTER_ADD_OVERIDE',
    payload: payload
  };
};

export const removeFilter = payload => {
  return {
    type: 'FILTER_REMOVE',
    payload: payload
  };
};

export const filtersCollapse = () => {
  return {
    type: 'FILTERS_COLLAPSE'
  };
};

export const filtersOpen = () => {
  return {
    type: 'FILTERS_OPEN'
  };
};

export const filtersInit = payload => {
  return {
    type: 'FILTERS_INIT',
    payload: payload
  };
};
