import React, { useState, useEffect } from 'react';
import './signupForm.scss';
import DelayLink from '../DelayLink/DelayLink';
import { useDispatch, connect } from 'react-redux';
import { signUp } from './../../store/auth/signUp/index';
import 'react-toastify/dist/ReactToastify.css';
import validate from './../../utils/validate';

const SignupForm = props => {
  const [inputsValues, setInputsValues] = useState({ email: '', password: '', passwordRepeat: '' });
  const dispatch = useDispatch();

  const handleSubmit = e => {
    e.preventDefault();

    if (validate(inputsValues, 'signup')) {
      dispatch(signUp(inputsValues));
    }
  };

  const handleOnChange = e => {
    setInputsValues({ ...inputsValues, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (props.user._id) {
      props.handleClose(true);
    }
  }, [props.user._id]);

  return (
    <form className='signUpForm'>
      <div className='input'>
        <input
          name='email'
          placeholder='E-mail'
          onChange={handleOnChange}
          value={inputsValues.email}
        />
        <div className='input-accent-line' />
      </div>
      <div className='input'>
        <input
          name='password'
          placeholder='Password'
          type='password'
          onChange={handleOnChange}
          value={inputsValues.password}
        />
        <div className='input-accent-line' />
      </div>
      <div className='input'>
        <input
          name='passwordRepeat'
          placeholder='Password repeat'
          type='password'
          onChange={handleOnChange}
          value={inputsValues.passwordRepeat}
        />
        <div className='input-accent-line' />
      </div>
      <button className='button-primary' onClick={handleSubmit}>
        Sign Up
      </button>
      <span className='bottom-span'>Already have account?</span>
      <DelayLink
        to='/login'
        delay={1300}
        onDelayStart={() => props.handleClose(false)}
        replace={true}
      >
        <span className='link'>Log In</span>
      </DelayLink>
    </form>
  );
};

const mapStateToProps = state => {
  return { user: state.auth.user };
};

export default connect(mapStateToProps)(SignupForm);
