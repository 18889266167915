import React, { useEffect, useRef } from 'react';
import './repeatButton.scss';
import { ReactComponent as Repeat } from '../../assets/repeat.svg';
import gsap from 'gsap';

const RepeatButton = props => {
  const repeatRef = useRef();

  useEffect(() => {
    const tl = gsap.timeline();

    if (!props.show) {
      tl.to(repeatRef.current, { opacity: 0, scale: 0.8, ease: 'Power3.easeInOut', duration: 0.5 });
    } else {
      tl.to(repeatRef.current, { opacity: 1, duration: 1 }, '-=0.3')
        .to(repeatRef.current, { scale: 1.2, duration: 0.4, ease: 'Power2.easeIn' }, '-=1')
        .to(repeatRef.current, { scale: 1, duration: 0.3, ease: 'Power2.easeOut' }, '-=0.6');
    }
  }, [props.show]);

  const handleHover = e => {
    if (e.type === 'mouseenter') {
      gsap.to(repeatRef.current, { scale: 1.1, duration: 0.5, ease: 'Power3.easeOut' });
    } else {
      gsap.to(repeatRef.current, { scale: 1, duration: 0.5, ease: 'Power3.easeOut' });
    }
  };

  return (
    <Repeat
      className='repeat-button'
      onClick={props.onClick}
      ref={repeatRef}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    />
  );
};

export default RepeatButton;
