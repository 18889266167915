import Axios from 'axios';
const axios = Axios.create();

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error.response);
  }
);

export default axios;
