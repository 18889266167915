import React, { useEffect, useRef, useState } from 'react';
import './movieCard.scss';
import { ReactComponent as Star } from '../../assets/star.svg';
import { ReactComponent as X } from '../../assets/X2.svg';
import { ReactComponent as Play2 } from '../../assets/play2.svg';
import { ReactComponent as Favorites } from '../../assets/favorites.svg';
import { ReactComponent as Watched } from '../../assets/watched.svg';
import { ReactComponent as WatchList } from '../../assets/watch-list.svg';
import IconHolder from '../iconHolder/iconHolder';
import YoutubePlayer from './../youtubePlayer/youtubePlayer';
import gsap from 'gsap';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import openInNewTab from './../../utils/openInNewTab';
import { addToList } from '../../store/auth/actions';
import { movieCardTransform, movieCardTransformReverse } from '../../animations/movieHolder';
import { votesFormat, calculateTime, shortOverview, writters } from './../../utils/MovieInfoUtils';

const MovieCard = props => {
  const infoRef = useRef();
  const xref = useRef();
  const movieCardRef = useRef();

  const [playTrailer, setPlayTrailer] = useState(false);
  const [inTransformation, setInTransformation] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.show) {
      setInTransformation(true);
      // document.addEventListener('wheel', handleOnScroll);

      movieCardTransform(movieCardRef, infoRef, props.posterRef, xref, setInTransformation);
    } else {
      // document.removeEventListener('wheel', handleOnScroll);
    }
  }, [props.show]);

  const handleClose = () => {
    props.setShow(false);
    setInTransformation(true);

    movieCardTransformReverse(movieCardRef, xref, props.posterRef, infoRef);
  };

  const handleLists = listName => {
    if (props.user?._id) {
      const add = props.user[listName]?.includes(props.movie._id) ? false : true;

      dispatch(
        addToList({
          userId: props.user._id,
          movieId: props.movie._id,
          add: add,
          listName: listName
        })
      );
    } else {
      history.push('/login');
    }
  };

  const handlePlay = e => {
    openInNewTab('https://www.amazon.com/gp/video/offers?tag=movierandom-20');
  };

  const handleIsInLIst = (ref, listName) => {
    if (ref && props.user?._id && props.movie._id) {
      const path = ref.getElementsByTagName('path')[0];
      path.style.fill = props.user[listName]?.includes(props.movie._id) ? '#DD003F' : '';
    }
  };

  const handleDetailsExpand = e => {
    if (!inTransformation && props.show && e.deltaY === 100) {
      gsap.to(movieCardRef.current, { height: 'auto' });
    }
  };

  return (
    <>
      {playTrailer && props.movie.videos[0] && (
        <YoutubePlayer id={props.movie.videos[0].key} setShow={setPlayTrailer} />
      )}
      <div className='movie-card' ref={movieCardRef} data-expand={props.show ? 'true' : 'false'}>
        <X className='x' ref={xref} onClick={handleClose} />
        <div className='wrapper'>
          <div className='info' ref={infoRef}>
            <h3 className='title'>{props.movie.title}</h3>
            <span className='genres'>{props.movie.genre.join(', ')}</span>
            <span className='year'>{props.movie.release_date.substr(0, 4)}</span>
            <span className='duration'>
              {props.movie.duration ? calculateTime(props.movie.duration) : '-'}
            </span>
            <div className='votes'>
              <Star className='star' />
              <span className='vote-average'>
                {props.movie.vote_average ? props.movie.vote_average : '-'}/10
              </span>
              <span className='vote-count'>
                {props.movie.vote_count ? votesFormat(props.movie.vote_count) + ' votes' : '-'}
              </span>
            </div>
            <p className='overview'>{shortOverview(props.movie.overview, 190)}</p>
            {props.movie.credits && (
              <>
                <h4>Stars</h4>
                <span className='stars'>
                  {props.movie.credits.cast.map((actor, index) => {
                    if (index < 3) {
                      return actor.name + ', ';
                    } else if (index === 3) {
                      return actor.name + '... ';
                    }
                    return null;
                  })}
                </span>
              </>
            )}
            <div className='bottom-container'>
              <div className='icons'>
                <IconHolder tooltip='Favorites'>
                  <Favorites
                    ref={ref => handleIsInLIst(ref, 'favorite')}
                    onClick={() => handleLists('favorite')}
                  />
                </IconHolder>
                <IconHolder tooltip='Watched'>
                  <Watched
                    ref={ref => handleIsInLIst(ref, 'watched')}
                    onClick={() => handleLists('watched')}
                  />
                </IconHolder>
                <IconHolder tooltip='Watch list'>
                  <WatchList
                    ref={ref => handleIsInLIst(ref, 'watchlist')}
                    onClick={() => handleLists('watchlist')}
                  />
                </IconHolder>
              </div>
              <div className='actions'>
                <span className='trailer' onClick={() => setPlayTrailer(true)}>
                  Watch Trailer
                </span>
                <button className='button-primary' onClick={handlePlay}>
                  Play Now <Play2 />
                </button>
              </div>
            </div>
          </div>
          <div className='details-holder'>
            <div className='separator'></div>
            <h4>Detail Overview:</h4>
            <p>{props.movie.overview}</p>

            <div className='row'>
              <div className='column'>
                {props.movie.credits && (
                  <>
                    <h4>Director:</h4>
                    {props.movie.credits.crew.map(item => {
                      if (item.job === 'Director') {
                        return <span key={item.name}>{item.name}</span>;
                      }
                    })}

                    <h4>Release date:</h4>
                    <span>{props.movie.release_date.replace('-', '.')}</span>
                  </>
                )}
              </div>

              <div className='column'>
                {props.movie.credits && (
                  <>
                    <h4>Writers:</h4>
                    {writters(props.movie.credits).map(item => {
                      return <span key={item}>{item}</span>;
                    })}

                    <h4>Budget:</h4>
                    <span>{props.movie.budget}</span>
                  </>
                )}
              </div>

              <div className='column'>
                {props.movie.credits && (
                  <>
                    <h4>Language:</h4>
                    {props.movie.language.map(language => {
                      return <span key={language}>{language}</span>;
                    })}

                    <h4>Revenue:</h4>
                    <span>{props.movie.revenue}</span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  if (state.auth.user?._id) {
    return { user: state.auth.user };
  } else {
    return {};
  }
};

export default connect(mapStateToProps)(MovieCard);
