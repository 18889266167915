import React, { useState } from 'react';
import './forgotPassword.scss';
import validate from '../../utils/validate';
import Auth from '../../models/Auth';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

const ForgotPassword = props => {
  const [inputsValues, setInputsValues] = useState({ email: '' });
  const history = useHistory();

  const handleSubmit = e => {
    e.preventDefault();

    if (validate(inputsValues, 'forgot')) {
      Auth.forgotPasswordRequest(inputsValues.email).then(res => {
        props.handleClose(true);
        // setTimeout(() => history.replace('/'), 1300);
      });
    }
  };

  const handleOnChange = e => {
    setInputsValues({ ...inputsValues, [e.target.name]: e.target.value });
  };

  return (
    <form className='forgotPassword'>
      <span
        className='bottom-span'
        style={{ width: '22rem', lineHeight: '2rem', marginBottom: '-1rem' }}
      >
        Enter your E-mail address and you will get link for password reset
      </span>
      <div className='input'>
        <input
          name='email'
          placeholder='E-mail'
          onChange={handleOnChange}
          value={inputsValues.email}
        />
        <div className='input-accent-line' />
      </div>
      <button className='button-primary' onClick={handleSubmit}>
        Send Request
      </button>
    </form>
  );
};

const mapStateToProps = state => {
  return { user: state.auth.user };
};

export default connect(mapStateToProps)(ForgotPassword);
