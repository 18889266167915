import React, { useState, useEffect, useRef } from 'react';
import './gridMovieHolder.scss';
import { ReactComponent as Holder } from '../../assets/Poster holder.svg';
import { ReactComponent as Play } from '../../assets/play.svg';
import { ReactComponent as Loader } from '../../assets/loader.svg';
import api from '../../api.json';
import gsap from 'gsap';
import InfoForPoster from '../infoForPoster/infoForPoster';
import RepeatButton from '../repeatButton/repeatButton';
import MovieCard from '../movieCard/movieCard';
import store from '../../store/store';
import { useDispatch, connect } from 'react-redux';
import { getMovie } from '../../store/movie/actions';
import * as fader from '../../utils/fader';

const GridMovieHolder = props => {
  const [posterStyle, setPosterStyle] = useState({});
  const [playStyle, setPlayStyle] = useState({});
  const [catcherStyle, setCatcherStyle] = useState({});
  const [cardExpand, setCardExpand] = useState(false);
  const [infoForPosterHide, setInfoForPosterHide] = useState(false);
  const [repeatShow, setRepeatShow] = useState(true);
  const [showCard, setShowCard] = useState(false);
  const [unloadDone, setUnloadDone] = useState(false);
  const [movieHolderProps, setMovieHolderProps] = useState({});
  const [faderRef, setFaderRef] = useState(null);

  const infoRef = useRef();

  const dispatch = useDispatch();

  const loadMovieAnimation = () => {
    setInfoForPosterHide(false);
    setRepeatShow(true);

    gsap.set(playRef.current, { clearProps: 'display' });

    gsap.to(loaderRef.current, {
      opacity: 0,
      ease: 'Power2.easeInOut',
      duration: 0.3
    });

    gsap.set(clickCatcherRef.current, { clearProps: 'display' });
    gsap.to(clickCatcherRef.current, { opacity: 1 });
  };

  const loadPoster = () => {
    const poster = new Image();
    // const poster = `url(${api.images}${movie.images.posters[0].file_path})`;
    poster.src = api.images + 'w500' + props.movie.images.posters[0].file_path;

    poster.onload = () => {
      setPosterStyle({
        backgroundImage: `url(${poster.src})`
      });
    };
  };

  const unloadMovieAnimation = () => {
    if (!props.movie._id) {
      setUnloadDone(true);
      setRepeatShow(false);
      return;
    }

    gsap.to(loaderRef.current, {
      opacity: 1,
      ease: 'Power2.easeInOut',
      duration: 0.3
    });

    const tl = gsap.timeline();

    tl.to(clickCatcherRef.current, { opacity: 0, duration: 0.3 })
      .to(playRef.current, { opacity: 0, duration: 0.3 }, '-=0.3')
      .set(clickCatcherRef.current, { display: 'none' }, '+=0.2')
      .set(playRef.current, { display: 'none' });

    setInfoForPosterHide(true);
    setRepeatShow(false);
  };

  const handleGetMovie = async () => {
    if (cardExpand) {
      return;
    }
    unloadMovieAnimation();
    const storeState = store.getState();

    dispatch(
      getMovie({
        filters: storeState.filters.filters,
        lastMovieId: props.movie._id ?? null
      })
    );
  };

  useEffect(() => {
    if (unloadDone && !props.movie.loading) {
      !cardExpand && loadMovieAnimation();
      setUnloadDone(false);
      loadPoster();
    } else if (props.info === false) {
      loadPoster();
    }
  }, [props.movie, props.movie.loading, unloadDone]);

  const movieHolderRef = useRef();
  const clickCatcherRef = useRef();
  const playRef = useRef();
  const loaderRef = useRef();
  const keeperRef = useRef();
  const posterRef = useRef();

  const handleTransformation = e => {
    const tl = gsap.timeline();
    const posterRefs = [...document.querySelectorAll('.movie-holder')].map(item =>
      item.querySelector('.poster')
    );

    setFaderRef(fader.add(document.querySelector('.grid-page'), 'beforeend'));

    if (!props.screen?.tabletPort) {
      tl.set(movieHolderRef.current, { zIndex: 1 })
        .to(movieHolderRef.current, {
          left: '50%',
          top: `calc(${document.querySelector('HTML').scrollTop}px + 50vh)`,
          x: '-50%',
          y: '-50%',
          width: '34.5rem',
          height: '51.8rem',
          duration: 1.2,
          ease: 'Power3.easeInOut'
        })
        .to(posterRefs, { boxShadow: '0rem 2rem 4rem rgba(0, 0, 0, 0.9)', duration: 1.2 }, '-=1.2')
        .call(setCardExpand, [true])
        .to(clickCatcherRef.current, { opacity: 0 })
        .set(clickCatcherRef.current, { display: 'none' })
        .call(setPlayStyle, [{ opacity: 0 }], 0)
        .call(setShowCard, [true], '-=0.8')
        .call(setInfoForPosterHide, [true]);
      // .call(loadBackdrop, [true]);
    } else {
      tl.set(movieHolderRef.current, { zIndex: 1 })
        .to(movieHolderRef.current, {
          left: '50%',
          top: `calc(${document.querySelector('HTML').scrollTop}px + 50vh)`,
          x: '-50%',
          y: '-50%',
          width: '28rem',
          height: '42rem',
          duration: 1.2,
          ease: 'Power3.easeInOut'
        })
        .call(setCardExpand, [true])
        .to(clickCatcherRef.current, { opacity: 0 })
        .set(clickCatcherRef.current, { display: 'none' }, '+=0.2')
        .call(setPlayStyle, [{ opacity: 0 }], 0)
        .call(setShowCard, [true], '-=0.8')
        .call(setInfoForPosterHide, [true]);
    }
  };

  const handleTransformationReverse = () => {
    const tl = gsap.timeline();
    const posterRefs = [...document.querySelectorAll('.movie-holder')].map(item =>
      item.querySelector('.poster')
    );

    tl.to(
      movieHolderRef.current,
      {
        top: movieHolderProps.top + 'px',
        left: movieHolderProps.left + 'px',
        width: movieHolderProps.width + 'px',
        height: movieHolderProps.height + 'px',
        x: '0%',
        y: '0%',
        duration: 1.2,
        ease: 'Power3.easeInOut'
      },
      '+=1.8'
    )
      .to(posterRefs, { boxShadow: '0rem 2rem 4rem rgba(0, 0, 0, 0)', duration: 1.2 }, '-=1.2')
      .call(fader.remove, [faderRef])
      .call(setInfoForPosterHide, [false])
      .set(clickCatcherRef.current, { display: 'block' })
      .to(clickCatcherRef.current, { opacity: 1 })
      .set(playRef.current, { display: 'block' }, '-=1')
      .set(movieHolderRef.current, { zIndex: 0 })
      .call(setCardExpand, [false]);
    // .call(loadBackdrop, [false]);
  };

  useEffect(() => {
    if (!showCard && cardExpand) {
      handleTransformationReverse();
    }
  }, [showCard]);

  const handleHover = e => {
    if (e.type === 'mouseenter') {
      setCatcherStyle({ boxShadow: '0 0 15rem rgba(0, 0, 0, 0.897) inset' });
      setPlayStyle({ opacity: !cardExpand && 1 });
    } else {
      setCatcherStyle({ boxShadow: '0 0 15rem rgba(0, 0, 0, 0) inset' });
      setPlayStyle({ opacity: 0 });
    }
  };

  useEffect(() => {
    handleUpdateResize();

    window.addEventListener('resize', handleUpdateResize);
    return () => window.removeEventListener('resize', handleUpdateResize);
  }, []);

  const handleUpdateResize = e => {
    const allRefs = document.querySelectorAll('.movie-holder');
    let expanded = false;

    if (allRefs) {
      const refs = [...allRefs].map(ref => ref.querySelector('.movie-card'));
      refs.map(ref => (ref.dataset.expand === 'true' ? (expanded = true) : null));
    }

    if (!expanded) {
      setMovieHolderProps({
        top: keeperRef.current.offsetTop,
        left: keeperRef.current.offsetLeft,
        width: keeperRef.current.offsetWidth,
        height: keeperRef.current.offsetHeight
      });
      gsap.set(movieHolderRef.current, {
        top: keeperRef.current.offsetTop,
        left: keeperRef.current.offsetLeft,
        width: keeperRef.current.getBoundingClientRect().width,
        height: keeperRef.current.getBoundingClientRect().height
      });
    }
  };

  useEffect(() => {
    if (!showCard) {
    }
  }, [window.innerWidth]);

  return (
    <>
      <div key={props.movie._id + 1} className='moive-place-keeper' ref={keeperRef}>
        <div className='moive-place-keeper__info'>
          <h3 className='moive-place-keeper__title'>{props.movie.title}</h3>
          <span className='moive-place-keeper__genres'>{props.movie.genre.join(', ')}</span>
        </div>
      </div>

      <div
        className='movie-holder'
        // style={props.movie._id && { width: '37.3rem', height: '56rem' }}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
        ref={movieHolderRef}
      >
        <div className='poster' style={posterStyle} ref={posterRef} />
        {props.movie.title ? (
          <>
            <Loader className='loader' ref={loaderRef} />

            {/* <InfoForPoster
              movie={props.movie}
              setUnloadDone={setUnloadDone}
              hide={infoForPosterHide}
              onlyTitle
            /> */}

            <Play className='play' style={playStyle} ref={playRef} />

            <div
              className='click-catcher'
              onClick={handleTransformation}
              ref={clickCatcherRef}
              style={catcherStyle}
            />

            <MovieCard
              key={props.movie._id}
              movie={props.movie}
              show={showCard}
              setShow={setShowCard}
              posterRef={posterRef}
            />
          </>
        ) : (
          <Holder className='holder' />
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    screen: state.UI?.screen && state.UI.screen
  };
};

export default connect(mapStateToProps)(GridMovieHolder);
