import React, { useEffect, useRef, useState } from 'react';
import './genreInput.scss';
import gsap from 'gsap';
import { capitalize } from './../../utils/stringUtils';

const GenreInput = props => {
  const menuRef = useRef();
  const containerRef = useRef();
  const ulRef = useRef();
  const scrollerRef = useRef();
  const thumbRef = useRef();

  const [thumbDown, setThumbDown] = useState(false);
  const [thumbDownPos, setThumbDownPos] = useState(0);
  const [touchStartPos, setStartTouchPos] = useState(0);
  const [touchStartScrollTop, setTouchStartScrollTop] = useState(0);
  const [animationDone, setAnimationDone] = useState(true);

  useEffect(() => {
    if (animationDone) {
      props.showed ? handleShow() : handleHide();
    } else {
      setTimeout(() => (props.showed ? handleShow() : handleHide()), 500);
    }
  }, [props.showed]);

  const handleShow = () => {
    setAnimationDone(false);
    gsap.set(menuRef.current, { display: 'block' });
    gsap
      .to(containerRef.current, { y: 0, ease: 'Power2.easeInOut' })
      .then(() => setAnimationDone(true));
    menuRef.current.focus();
  };

  const handleHide = () => {
    setAnimationDone(false);
    gsap
      .timeline()
      .to(containerRef.current, { y: '-100%', ease: 'Power2.easeInOut' })
      .set(menuRef.current, {
        display: 'none'
      })
      .then(() => setAnimationDone(true));
  };

  const handleScroll = e => {
    if (
      (e.type === 'mousemove' && thumbDown) ||
      (e.type === 'touchmove' && thumbDown) ||
      e.type === 'wheel' ||
      e.type === 'keydown'
    ) {
      const thumbHeight = thumbRef.current.clientHeight;
      const scrollerHeight = scrollerRef.current.clientHeight;
      const ulHeight = ulRef.current.scrollHeight;

      const avarage = ulHeight / (scrollerHeight - thumbHeight);

      if (e.type === 'mousemove') {
        const scrollerTop = scrollerRef.current.getBoundingClientRect().top;
        const thumbTop = (e.touches?.[0].clientY ?? e.clientY) - scrollerTop - thumbDownPos;

        if (thumbTop >= 0 && thumbTop <= scrollerHeight - thumbHeight) {
          thumbRef.current.style.top = thumbTop + 'px';
          ulRef.current.scrollTop = avarage * thumbTop;
        }
      } else if (e.type === 'wheel') {
        if (e.deltaY > 0) {
          ulRef.current.scrollTop = ulRef.current.scrollTop + 30;
        } else {
          ulRef.current.scrollTop = ulRef.current.scrollTop - 30;
        }
        thumbRef.current.style.top = ulRef.current.scrollTop / avarage + 'px';
      } else if (e.type === 'keydown') {
        // li navigation scroller update
        thumbRef.current.style.top = ulRef.current.scrollTop / avarage + 'px';
      } else if (e.type === 'touchmove') {
        const pos = touchStartScrollTop + (e.touches[0].clientY - touchStartPos) * -1;
        if (pos < ulRef.current.scrollHeight && pos > 0) {
          ulRef.current.scrollTop = pos;
          thumbRef.current.style.top = pos / avarage + 'px';
        }
      }
    }
  };

  const handleThumbClickPos = clientY => {
    const thumbTop = thumbRef.current.getBoundingClientRect().top;
    const mouseClickPos = clientY - thumbTop;
    setThumbDownPos(mouseClickPos);
  };

  const handleKeyPress = e => {
    e.preventDefault();
    if (e.keyCode === 40) {
      if (e.target.tabIndex < ulRef.current.children.length - 1) {
        ulRef.current.children[e.target.tabIndex + 1].focus();
        handleScroll(e);
      }
    } else if (e.keyCode === 38) {
      if (e.target.tabIndex > 0) {
        ulRef.current.children[e.target.tabIndex - 1].focus();
        handleScroll(e);
      }
    } else if (e.keyCode === 13) {
      handleSetValue(e.target.innerText);
    } else if (e.keyCode === 27) {
      props.setShowed(false);
    }
  };

  const handleSetValue = value => {
    props.setValue(capitalize(value));
  };

  const preventScrolling = is => {
    if (is) {
      document.body.style.overscrollBehavior = 'none';
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
    } else {
      document.body.style.overscrollBehavior = 'auto';
      document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';
    }
  };

  const handleTouchStart = e => {
    setThumbDown(true);
    setStartTouchPos(e.touches[0].clientY);
    setTouchStartScrollTop(ulRef.current.scrollTop);
    preventScrolling(true);
    props.blockScroll(true);
  };

  const handleTouchEnd = e => {
    thumbDown && setThumbDown(false);
    touchStartPos > 0 && setStartTouchPos(0);
    preventScrolling(false);
    props.blockScroll(false);
  };

  useEffect(() => {
    menuRef.current.onwheel = function() {
      return false;
    };
  }, []);

  return (
    <div
      className='drop-menu'
      onMouseMove={handleScroll}
      onTouchMove={handleScroll}
      onMouseLeave={() => thumbDown && setThumbDown(false)}
      onTouchEnd={handleTouchEnd}
      onMouseUp={handleTouchEnd}
      onWheel={handleScroll}
      ref={menuRef}
      onKeyDown={handleKeyPress}
      tabIndex='1'
    >
      <div className='container' ref={containerRef}>
        <ul
          ref={ulRef}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={() => thumbDown && handleScroll}
        >
          {props.items.map((item, index) => {
            return (
              <li key={index} onClick={e => handleSetValue(e.target.innerText)} tabIndex={index}>
                {item}
              </li>
            );
          })}
        </ul>

        <div className='menu-scroller' ref={scrollerRef}>
          <div className='menu-scroller__holder'>
            <div
              className='menu-scroller__thumb'
              ref={thumbRef}
              onMouseDown={e => {
                handleThumbClickPos(e.clientY);
                setThumbDown(true);
              }}
              onTouchStart={e => {
                handleThumbClickPos(e.clientY);
                setThumbDown(true);
              }}
              onMouseUp={() => setThumbDown(false)}
              onTouchEnd={() => setThumbDown(false)}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenreInput;
