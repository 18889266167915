import React, { useEffect, useRef } from 'react';
import './banner.scss';
import { ReactComponent as X } from '../../assets/X.svg';
import gsap from 'gsap';
import { connect } from 'react-redux';

const Banner = props => {
  const bannerRef = useRef();

  useEffect(() => {
    handleOpen();
  }, []);

  const handleOpen = () => {
    gsap
      .timeline()
      .set(bannerRef.current, { display: 'block' }, '+=2')
      .from(bannerRef.current, {
        opacity: 0,
        y: '100%',
        duration: 1,
        ease: 'Power2.easeInOut'
      })
      .set(bannerRef.current, { clearProps: 'y' });
  };

  const handleClose = () => {
    gsap
      .timeline()
      .to(bannerRef.current, { opacity: 0, y: '100%', duration: 1, ease: 'Power2.easeInOut' })
      .set(bannerRef.current, { clearProps: 'all' });
  };

  return (
    <div className='banner' ref={bannerRef}>
      {props.screen?.tabPort || props.screen?.phone ? (
        <iframe
          src='//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=primeent&banner=0PDYZ1WNDGPDAT9V4SG2&f=ifr&linkID=be24eb53ebb564c6d63fc1b287e8c33d&t=movierandom-20&tracking_id=movierandom-20'
          width='300'
          height='250'
          scrolling='no'
          border='0'
          marginWidth='0'
          style={{ border: 'none' }}
          frameBorder='0'
        ></iframe>
      ) : (
        <iframe
          src='//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=primeent&banner=0P8MXD2V4VN4V0E301G2&f=ifr&lt1=_blank&linkID=08edffb1388146a8555f5baebf9223c5&t=movierandom-20&tracking_id=movierandom-20'
          width='728'
          height='90'
          scrolling='no'
          border='0'
          marginWidth='0'
          style={{ border: 'none' }}
          frameBorder='0'
        ></iframe>
      )}

      <X onClick={handleClose} />
    </div>
  );
};

const mapStateToProps = state => {
  return { screen: state.UI?.screen && state.UI.screen };
};

export default connect(mapStateToProps)(Banner);
