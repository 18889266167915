import React, { useRef, useEffect } from 'react';
import './filter.scss';
import { ReactComponent as X } from '../../assets/X.svg';
import gsap from 'gsap';

const Filter = props => {
  const xref = useRef();
  const filterRef = useRef();
  const labelRef = useRef();

  const mouseEnter = () => {
    gsap.to(xref.current, { opacity: 1, marginRight: '-0.4rem', duration: 0.3 });
  };

  const mouseLeave = () => {
    gsap.to(xref.current, { opacity: 0, marginRight: '-2.4rem', duration: 0.3 });
  };

  useEffect(() => {
    const tl = gsap.timeline();
    tl.set(filterRef.current, { display: 'flex' })
      .from(filterRef.current, {
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 0,
        width: 0,
        opacity: 0,
        ease: 'Power2.easeInOut',
        duration: 0.7
      })
      .set(filterRef.current, { clearProps: 'width' })
      .from(labelRef.current, { opacity: 0 }, '-=0.5');
  }, []);

  const handleRemove = e => {
    const tl = gsap.timeline();
    tl.to(labelRef.current, { opacity: 0 })
      .to(
        filterRef.current,
        {
          paddingLeft: 0,
          paddingRight: 0,
          marginLeft: 0,
          width: 0,
          opacity: 0,
          ease: 'Power3.easeInOut',
          duration: 0.7
        },
        '-=0.7'
      )
      .then(() => {
        props.remove(labelRef.current.innerText);
      });
  };

  return (
    <div className='filter' onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} ref={filterRef}>
      <span className='label' ref={labelRef}>
        {props.label}
      </span>
      <X ref={xref} onClick={handleRemove} />
    </div>
  );
};

export default Filter;
