const initState = {
  pageInTransition: false,
  screen: {
    tabletLand: false,
    tabletPort: false,
    phone: false
  },
  modal: false
};

export default (state = initState, action) => {
  switch (action.type) {
    case 'PAGE_IN_TRANSITION_START':
      return {
        ...state,
        pageInTransition: true
      };
    case 'PAGE_IN_TRANSITION_END':
      return {
        ...state,
        pageInTransition: false
      };
    case 'SCREEN_CHANGE':
      return {
        ...state,
        screen: {
          ...action.screen
        }
      };
    case 'MODAL_SHOWED':
      return {
        ...state,
        modal: true
      };
    case 'MODAL_CLOSED':
      return {
        ...state,
        modal: false
      };
    default:
      return state;
  }
};
