import React, { useEffect, useState } from 'react';
import './gridPage.scss';
import { connect, useDispatch } from 'react-redux';
import {
  getPopularMovies,
  getTopRatedMovies,
  getUpcomingMovies,
  getInTheater
} from '../../store/movie/actions';
import GridMovieHolder from '../../components/gridMovieHolder/gridMovieHolder';
import { filtersCollapse } from '../../store/filters/actions';
import { ReactComponent as Loader } from '../../assets/loader.svg';
import gsap from 'gsap';
import { capitalize } from './../../utils/stringUtils';
import { Helmet } from 'react-helmet';

const GridPage = props => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('Movie Recommend')

  const movieListDispatch = list => {
    switch (list) {
      case 'popular':
        setTitle('Popular');
        dispatch(getPopularMovies(1, 20));
        break;
      case 'toprated':
        setTitle('Top Rated');
        dispatch(getTopRatedMovies(1, 20));
        break;
      case 'intheaters':
        setTitle('In Theaters');
        dispatch(getInTheater(1, 20));
        break;
      case 'upcoming':
        setTitle('Upcoming');
        dispatch(getUpcomingMovies(1, 20));
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    if (props.movies[props.name].movies.length === 0) {
      movieListDispatch(props.name);
    }

    dispatch(filtersCollapse());

    document.querySelector('.bottom-blue') &&
      gsap.to('.bottom-blue', {
        height: '100vh',
        position: 'fixed',
        duration: 1.3,
        ease: 'Power2.easeInOut'
      });
  }, [props.name]);

  useEffect(() => {
    const refs = document.querySelectorAll('.movie-holder');
    const posterRefs = [...document.querySelectorAll('.movie-holder')].map(item =>
      item.querySelector('.poster')
    );

    gsap.set(refs, { opacity: 1 });
    gsap.set('.moive-place-keeper__info', { opacity: 1 });
    gsap.set(posterRefs, { boxShadow: '0rem 2rem 4rem rgba(0, 0, 0, 0)' });
    gsap
      .timeline()
      .from(refs, {
        y: '-50',
        opacity: 0,
        stagger: { amount: 1 },
        ease: 'Power3.easeOut',
        duration: 1,
        delay: 0.7
      })
      .from(
        '.moive-place-keeper__info',
        { opacity: 0, stagger: { amount: 1 }, duration: 1, delay: 0.7 },
        '-=2.5'
      );
  }, [props.movies[props.name].movies]);

  return (
    <div className='grid-page'>
      <Helmet>
        <title>Movie Recommend - {title}</title>
        <meta name='description' content={`${title} movies`} />
      </Helmet>
      {props.movies[props.name].movies.length > 0 ? (
        props.movies[props.name].movies.map(movie => (
          <GridMovieHolder key={movie._id} id={movie._id} movie={movie} info={false} />
        ))
      ) : (
        <Loader className='loader' />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    movies: state.movies
  };
};

export default connect(mapStateToProps)(GridPage);
