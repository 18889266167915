import React, { useEffect, useRef, useState } from 'react';
import './filters.scss';
import FilterProperty from '../../components/filterProperty/filterProperty';
import { genres } from '../../genres.json';
import { connect } from 'react-redux';
import gsap from 'gsap';
import { ReactComponent as Arrow } from '../../assets/arrow-right.svg';
import SwipeArrowIndicator from '../../components/swipeArrowIndicator/swipeArrowIndicator';

const Filters = props => {
  const [touchStart, setTouchStart] = useState(0);
  const [scrollLeftOnStart, setScrollLeftOnStart] = useState(0);
  const [marginLeftOnStart, setMarginLeftOnStart] = useState(0);
  const [blockScroll, setBlockScroll] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);

  const filtersRef = useRef();
  const wrapperRef = useRef();
  const swipeIndicator = useRef();

  useEffect(() => {
    if (props.filters.isOpen) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [props.filters.isOpen]);

  const handleOpen = () => {
    gsap.to(filtersRef.current, { y: 0, duration: 1, ease: 'Power4.easeInOut' });
  };

  const handleClose = () => {
    gsap.to(filtersRef.current, { y: '-9rem', duration: 1, ease: 'Power4.easeInOut' });
  };

  let lastMarginLeft = 0;

  const handleFiltersSwipe = e => {
    if (e.type === 'mousemove' && !mouseDown) {
      return;
    }
    const wrapperWidth = wrapperRef.current.scrollWidth;

    if (!blockScroll && wrapperWidth > window.innerWidth) {
      const x = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;
      const amount = touchStart - x;
      const marginLeftPosition = marginLeftOnStart ? marginLeftOnStart * -1 + amount : amount;

      // wrapperRef.current.scroll(scrollLeftOnStart + amount, 0);

      if (marginLeftPosition > 0) {
        if (
          marginLeftPosition < wrapperWidth - window.innerWidth + 70 ||
          (lastMarginLeft > 0 && marginLeftPosition < lastMarginLeft)
        ) {
          wrapperRef.current.style.marginLeft = marginLeftPosition * -1 + 'px';
          if (lastMarginLeft > 0) {
            lastMarginLeft = 0;
          }
        } else {
          if (lastMarginLeft === 0) {
            lastMarginLeft = marginLeftPosition;
          }
        }
      }
    }
  };

  useEffect(() => {
    if (wrapperRef.current?.scrollWidth > window.innerWidth) {
      gsap.to(swipeIndicator.current, { opacity: 1 });
    } else {
      gsap.to(swipeIndicator.current, { opacity: 0 });
    }
  }, [wrapperRef.current?.scrollWidth, props.filters]);

  return (
    <div
      className='filters'
      ref={filtersRef}
      onTouchMove={handleFiltersSwipe}
      onMouseMove={handleFiltersSwipe}
      onTouchStart={e => {
        setTouchStart(e.touches[0].clientX);
        setScrollLeftOnStart(wrapperRef.current.scrollLeft);
        setMarginLeftOnStart(parseInt(wrapperRef.current.style.getPropertyValue('margin-left')));
      }}
      onMouseDown={e => {
        setTouchStart(e.clientX);
        setScrollLeftOnStart(wrapperRef.current.scrollLeft);
        setMarginLeftOnStart(parseInt(wrapperRef.current.style.getPropertyValue('margin-left')));
        setMouseDown(true);
      }}
      onMouseUp={() => setMouseDown(false)}
    >
      <div className='overflow-wrapper' ref={wrapperRef}>
        <FilterProperty
          name='Genre'
          items={genres}
          filters={props.filters}
          setFilters={props.setFilters}
          type='list'
          blockScroll={setBlockScroll}
        />
        <FilterProperty
          name='Rating'
          items={[]}
          filters={props.filters}
          setFilters={props.setFilters}
          type='range'
          min={0}
          max={10}
          step={0.1}
          blockScroll={setBlockScroll}
        />
        <FilterProperty
          name='Year'
          items={[]}
          filters={props.filters}
          setFilters={props.setFilters}
          type='range'
          min={1980}
          max={new Date().getFullYear()}
          step={1}
          blockScroll={setBlockScroll}
        />
        <FilterProperty
          name='Cast'
          items={[]}
          filters={props.filters}
          setFilters={props.setFilters}
          type='input'
          blockScroll={setBlockScroll}
        />
        <div className='accent-line' />
        <div className='swipe-indicator' ref={swipeIndicator}>
          <SwipeArrowIndicator />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return { filters: state.filters };
};

export default connect(mapStateToProps)(Filters);
