import gsap from 'gsap';
import { isTabletPort } from '../utils/screens';
import { filtersCollapse } from '../store/filters/actions';
import { filtersOpen } from './../store/filters/actions';

export const movieHolderTransform = (
  clickCatcherRef,
  setRepeatShow,
  dispatch,
  setPlayStyle,
  setShowCard,
  movieHolderRef,
  setInfoForPosterHide,
  loadBackdrop
) => {
  const tl = gsap.timeline();

  tl.to(clickCatcherRef.current, { opacity: 0 })
    .set(clickCatcherRef.current, { display: 'none' })
    .call(setRepeatShow, [false])
    .to('.bottom-blue', { height: 0, duration: 1.1, ease: 'Power4.easeInOut' }, '-=0.6')
    .call(dispatch, [filtersCollapse()], '-=1')
    .call(setPlayStyle, [{ opacity: 0 }], 0)
    .call(setShowCard, [true], '-=0.6');

  if (!isTabletPort()) {
    tl.to(
      movieHolderRef.current,
      { width: '34.5rem', height: '51.8rem', y: '2rem', duration: 0.6 },
      0.2
    );
  }
  tl.call(setInfoForPosterHide, [true], 0.2);

  if (!isTabletPort()) {
    tl.call(loadBackdrop, [true], '+=2.7');
  }

  return new Promise((resolve, reject) => {
    tl.then(() => resolve());
  });
};

export const movieCardTransform = (movieCardRef, infoRef, posterRef, xref, setInTransformation) => {
  const tl = gsap.timeline();

  if (!isTabletPort()) {
    tl.set(movieCardRef.current, { opacity: 1 })
      .to(movieCardRef.current, {
        minWidth: '43rem',
        height: '50.8rem',
        duration: 0.6
      })
      .set(infoRef.current, {
        opacity: 1
      })
      .to(
        infoRef.current,
        {
          marginLeft: '36.5rem',
          duration: 0.9,
          ease: 'Power3.easeInOut'
        },
        '+=0.7'
      )
      .to(
        posterRef.current,
        { marginLeft: '-95%', duration: 0.9, ease: 'Power3.easeInOut' },
        '-=0.9'
      )
      .to(xref.current, { opacity: 1 }, '-=0.9')
      .call(setInTransformation, [false]);
  } else {
    tl.set(infoRef.current, {
      opacity: 1
    })
      .to(
        posterRef.current,
        { y: -100, opacity: 0, duration: 0.9, ease: 'Power3.easeInOut' },
        '+=0.8'
      )
      .set(posterRef.current, { display: 'none' })
      .to(movieCardRef.current, { opacity: 1, duration: 1, ease: 'Power3.easeInOut' })
      .fromTo(
        movieCardRef.current,
        { top: '-25rem' },
        { top: '-6rem', duration: 1, ease: 'Power3.easeInOut' },
        '-=1'
      )
      .to(xref.current, { opacity: 1 }, '-=0.9')
      .call(setInTransformation, [false]);
  }

  return new Promise((resolve, reject) => {
    tl.then(() => {
      resolve();
    });
  });
};

export const movieHolderTransfromReverse = (
  movieHolderRef,
  dispatch,
  setRepeatShow,
  setInfoForPosterHide,
  clickCatcherRef,
  playRef,
  setCardExpand,
  loadBackdrop
) => {
  const tl = gsap.timeline();

  if (!isTabletPort()) {
    tl.to(
      movieHolderRef.current,
      {
        width: '37.3rem',
        height: '56rem',
        y: 0,
        duration: 1.5,
        ease: 'Power3.easeInOut'
      },
      '+=1'
    )
      .to(
        '.bottom-blue',
        {
          height: '30rem',
          duration: 1,
          ease: 'Power3.easeInOut'
        },
        '-=1'
      )
      .call(dispatch, [filtersOpen()], '-=1')
      .call(setRepeatShow, [true])
      .call(setInfoForPosterHide, [false])
      .set(clickCatcherRef.current, { clearProps: 'display' }, '-=1')
      .to(clickCatcherRef.current, { opacity: 1 })
      .to(playRef.current, { display: 'block' }, '-=1')
      .call(setCardExpand, [false])
      .call(loadBackdrop, [false], '+=1.5');
  } else {
    tl.to('.bottom-blue', {
      height: '31%',
      duration: 1,
      ease: 'Power3.easeInOut'
    })
      .call(dispatch, [filtersOpen()], '-=1')
      .call(setRepeatShow, [true])
      .call(setInfoForPosterHide, [false])
      .to(clickCatcherRef.current, { display: 'block' }, '+=2')
      .to(playRef.current, { display: 'block' }, '-=1')
      .call(setCardExpand, [false]);
  }
};

export const movieCardTransformReverse = (movieCardRef, xref, posterRef, infoRef) => {
  const tl = gsap.timeline();

  if (!isTabletPort()) {
    tl.to(
      movieCardRef.current,
      {
        minWidth: '17rem',
        height: '42rem',
        duration: 1.5,
        ease: 'Power3.easeInOut'
      },
      '+=1'
    )
      .to(xref.current, { opacity: 0 }, '-=1.6')
      .to(movieCardRef.current, { opacity: 0, duration: 0.8 }, '-=0.5')
      .to(posterRef.current, { marginLeft: 0, duration: 1, ease: 'Power3.easeInOut' }, 0.3)
      .to(
        infoRef.current,
        {
          marginLeft: '-38rem',
          duration: 1,
          ease: 'Power3.easeInOut'
        },
        0.3
      )
      .to(
        infoRef.current,
        {
          opacity: 0,
          duration: 0.3
        },
        1
      );
  } else {
    tl.set(posterRef.current, { clearProps: 'display' })
      .to(movieCardRef.current, { opacity: 0, duration: 1, ease: 'Power3.easeInOut' })
      .to(movieCardRef.current, { top: '-25rem', duration: 1, ease: 'Power3.easeInOut' }, '-=1')
      .to(posterRef.current, { y: 0, opacity: 1, duration: 0.9, ease: 'Power3.easeInOut' }, 0.3)
      .set(movieCardRef.current, { clearProps: 'all' });
  }
};
