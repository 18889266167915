import React, { useEffect, useRef } from 'react';
import './about.scss';
import TextPageTemplate from '../textPageTemplate/textPageTemplate';
import { ReactComponent as Arrow } from '../../assets/arrow-right-suggest.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as Facebook } from '../../assets/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/instagram.svg';
import { ReactComponent as Twitter } from '../../assets/twitter.svg';
import gsap from 'gsap';
import { Helmet } from 'react-helmet';

const About = () => {
  const helpRef = useRef();

  useEffect(() => {
    gsap
      .timeline()
      .from(helpRef.current, { width: 0, paddingLeft: 0, paddingRight: 0 }, '+=4')
      .set(helpRef.current, { clearProps: 'all' });
  }, []);

  return (
    <TextPageTemplate title='About'>
      <Helmet>
        <title>Movie Recommend - About</title>
        <meta name='description' content='About Movie Recommend' />
      </Helmet>
      <div className='typography'>
        <h3>What is Movie Recommend?</h3>
        <p>
          Movie Recommend is web app that helps users to find out great movies. You can let app to
          choose movie for you by using smart algorithms or to apply filters by your taste.
        </p>
        <div className='load-div'></div>
      </div>

      <div className='typography'>
        <h3>How did everything started?</h3>
        <p>
          Movie Recommend app is born from love about programming and movies. We thought, what about
          we developed an app that tracks movie ratings and reviews, and then choose some of the
          best for us? And here we are...
        </p>
        <div className='load-div'></div>
      </div>

      <div className='typography'>
        <h3>One more thing</h3>
        <p>
          Movie Recommend provides you links for movies on Amazon Prime Video streaming service. If
          you don't have subscription on Amazon Prime Video, you can sign up for free. Just click on
          Play Now button on movie that you like.
        </p>
        <div className='load-div'></div>
      </div>

      <div className='typography'>
        <div className='social-wrapper'>
          <h3>Follow us on:</h3>
          <div className='social-holder'>
            <Link to=''>
              <Facebook />
            </Link>
            <Link to=''>
              <Twitter />
            </Link>
            <Link to=''>
              <Instagram />
            </Link>
          </div>
          <div className='load-div'></div>
        </div>
      </div>

      <div className='help' ref={helpRef}>
        <h3>Want to help?</h3>
        <p>Have a suggestions how to improve Movie Recommend?</p>
        <Link to='/contact'>
          <span>Let us know</span>
          <Arrow className='help__arrow-suggest' />
        </Link>
      </div>
    </TextPageTemplate>
  );
};

export default About;
