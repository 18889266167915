import { toast, Slide } from 'react-toastify';

toast.configure({
  autoClose: 5000,
  transition: Slide,
  type: 'Error',
  className: 'toastify-custom',
  bodyClassName: 'toastify-body-custom'
});

const validate = (inputsValues, type) => {
  let res = true;
  if (type !== 'newPassword' && !validateEmail(inputsValues.email)) {
    toast.error('Invalid email');
    res = false;
  }
  if (type !== 'forgot' && type !== 'contact') {
    if (inputsValues.password.length <= 5) {
      toast.error('Password must contain at least 5 characters');
      res = false;
    }
  } else if (type === 'signup' || type === 'newPassword') {
    if (inputsValues.password !== inputsValues.passwordRepeat) {
      toast.error('Password are not same');
      res = false;
    }
  } else if (type === 'contact') {
    if (inputsValues.name.length < 4) {
      toast.error('Full Name must contain at least 4 characters');
      res = false;
    } else if (inputsValues.message.length < 15) {
      toast.error('Message must contain at least 15 characters');
      res = false;
    }
  }
  return res;
};

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default validate;
