import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import './infoForPoster.scss';
import { ReactComponent as Star } from '../../assets/star.svg';
import gsap from 'gsap';
import useObservable from '../../hooks/useObservable';

const InfoForPoster = props => {
  const titleRef = useRef();
  const genresRef = useRef();
  const votesRef = useRef();
  const infoHolderRef = useRef();

  const [infoHolderStyle, setInfoHolderStyle] = useState({});
  const [titleStyle, setTitleStyle] = useState({});

  useEffect(() => {
    const infoTl = gsap.timeline();

    if (props.hide) {
      infoTl
        .to(
          [
            titleRef.current,
            !props.onlyTitle && genresRef.current,
            !props.onlyTitle && votesRef.current
          ],
          {
            x: '-70rem',
            stagger: { amount: 0.5 },
            ease: 'Power2.easeInOut',
            duration: 1.2
          }
        )
        .to(
          infoHolderRef.current,
          {
            height: '0',
            paddingTop: '0',
            paddingBottom: '0',
            duration: 0.8,
            ease: 'Power3.easeInOut'
          },
          '-=1'
        )
        .then(() => {
          props.setUnloadDone(true);
        });
    } else {
      infoTl
        .to(infoHolderRef.current, {
          height: 'auto',
          duration: 0.7,
          ease: 'Power3.easeIn'
        })
        .to(infoHolderRef.current, {
          paddingTop: '',
          paddingBottom: '',
          duration: 0.3,
          ease: 'Power3.easeOut'
        })
        .to(
          [
            titleRef.current,
            !props.onlyTitle && genresRef.current,
            !props.onlyTitle && votesRef.current
          ],
          {
            x: 0,
            stagger: { amount: 0.5 },
            ease: 'Power2.easeInOut',
            duration: 1.2
          },
          '-=0.7'
        );
    }
  }, [props.hide]);

  const wrapperRef = useRef();

  const [containerWidth, setContainerWidth] = useState(0);

  const containerWidthObservable = setInterval(() => {
    if (infoHolderRef.current && infoHolderRef.current.offsetWidth !== containerWidth) {
      clearInterval(containerWidthObservable);
      setContainerWidth(infoHolderRef.current.offsetWidth);
    }
  }, 200);

  useEffect(() => {
    if (containerWidth > 0) {
      setInfoHolderStyle({
        padding:
          infoHolderRef.current.offsetWidth > 220
            ? '1.2rem 2.7rem 1.7rem 2.7rem'
            : '1.2rem 1.5rem 1.7rem 1.5rem'
      });
      setTitleStyle({
        fontSize: titleRef.current.offsetWidth > 220 ? '3rem' : '2.5rem'
      });
    }
  }, [containerWidth]);

  return (
    <div className='info-holder' ref={infoHolderRef} style={infoHolderStyle}>
      <div className='wrapper' ref={wrapperRef}>
        <h3 className='title' ref={titleRef} style={titleStyle}>
          {props.movie.title}
        </h3>
        {!props.onlyTitle && (
          <>
            <span className='genres' ref={genresRef}>
              {props.movie.genre.join(', ')}
            </span>
            <div className='votes' ref={votesRef}>
              <Star className='star' />
              <span>{props.movie.vote_average}/10</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InfoForPoster;
