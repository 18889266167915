import React, { useEffect, useRef } from 'react';
import './userMenu.scss';
import gsap from 'gsap';
import { ReactComponent as FavoritesIcon } from '../../assets/favorites.svg';
import { ReactComponent as WatchListIcon } from '../../assets/watch-list.svg';
import { ReactComponent as WatchedIcon } from '../../assets/watched.svg';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/auth/login';
import { useHistory } from 'react-router-dom';

const UserMenu = props => {
  const menuRef = useRef();
  const containerRef = useRef();
  const ulRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    props.toggleMenu ? handleShow() : handleHide();
    menuRef.current.dataset.visible = props.toggleMenu;
  }, [props.toggleMenu]);

  const handleClickOutside = e => {
    if (menuRef.current.dataset.visible === 'true' && !menuRef.current.contains(e.target)) {
      props.setToggleMenu(false);
    }
  };

  const handleShow = () => {
    gsap.set(menuRef.current, { display: 'block' });
    gsap.to(containerRef.current, { y: 0, ease: 'Power2.easeInOut' });
  };

  const handleHide = () => {
    const tl = gsap.timeline();
    tl.to(containerRef.current, { y: '-100%', ease: 'Power2.easeInOut' }).set(menuRef.current, {
      display: 'none'
    });
  };

  const handleListsOpen = e => {
    history.push('/' + e.target.getAttribute('name'));
    props.setToggleMenu(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <div className='user-menu' ref={menuRef}>
      <div className='container' ref={containerRef}>
        <ul ref={ulRef}>
          <li onClick={handleListsOpen} name='favorite'>
            <FavoritesIcon className='icon' />
            Favorites
          </li>
          <li onClick={handleListsOpen} name='watchList'>
            <WatchListIcon className='icon' />
            Watch list
          </li>
          <li onClick={handleListsOpen} name='watched'>
            <WatchedIcon className='icon' />
            Watched
          </li>
          <li className='logout' onClick={() => dispatch(logout())}>
            Log out
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserMenu;
