import React from 'react';
import { ReactComponent as FavoritesIcon } from '../../assets/favorites.svg';
import { ReactComponent as WatchListIcon } from '../../assets/watch-list.svg';
import { ReactComponent as WatchedIcon } from '../../assets/watched.svg';

const UserMenuUl = props => {
  return (
    <>
      <li>
        <FavoritesIcon className='icon' />
        <a onClick={props.onClick} name='favorite'>
          Favorites
        </a>
      </li>
      <li>
        <WatchListIcon className='icon' />
        <a onClick={props.onClick} name='watchList'>
          Watch list
        </a>
      </li>
      <li>
        <WatchedIcon className='icon' />
        <a onClick={props.onClick} name='watched'>
          Watched
        </a>
      </li>
    </>
  );
};

export default UserMenuUl;
