export const isTablet = () => {
  return window.screen.width <= 1200;
};

export const isTabletPort = () => {
  return window.screen.width <= 900;
};

export const isPhone = () => {
  return window.screen.width <= 600;
};
