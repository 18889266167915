import React, { useEffect, useRef, useState } from 'react';
import './home.scss';
import MovieHolder from '../../components/movieHolder/movieHolder';
import { ReactComponent as MovieTape } from '../../assets/movie-tape.svg';
import { useDispatch, connect } from 'react-redux';
import { getMovie } from './../../store/movie/actions';
import gsap from 'gsap';
import { filtersOpen } from './../../store/filters/actions';
import { Helmet } from 'react-helmet';

const Home = props => {
  const pageRef = useRef();
  const wrapperRef = useRef();
  const dispatch = useDispatch();
  const movieTapeRef = useRef();
  const buttonRef = useRef();
  const movieHolderRef = useRef();

  const [movieLoaded, setMovieLoaded] = useState(false);

  const handleOnClick = e => {
    dispatch(getMovie());
    animateOut();
    setMovieLoaded(true);
  };

  const animateOut = () => {
    const tl = gsap.timeline();

    if (movieLoaded) {
      tl.to('.movie-holder', { y: '-20rem', opacity: 0, duration: 0.8, ease: 'Power3.easeIn' });
      return;
    }

    tl.set(buttonRef.current, { transition: 'none' })
      .to([...wrapperRef.current.children, movieTapeRef.current], {
        y: -100,
        opacity: 0,
        stagger: { amount: 0.3 },
        ease: 'Power2.easeIn',
        duration: 0.7
      })
      .to(pageRef.current, { backgroundColor: 'rgba(17, 17, 23, 0)', duration: 1.3 })
      .set([wrapperRef.current, movieTapeRef.current], { display: 'none' })
      .set('.movie-holder', { display: 'block' })
      .from('.movie-holder', { y: 100, opacity: 0, ease: 'Power2.easeOut', duration: 0.8 });
  };

  useEffect(() => {
    gsap.timeline().to('.bottom-blue', {
      height: '31vh',
      position: 'absolute',
      duration: 1.3,
      ease: 'Power2.easeInOut'
    });

    dispatch(filtersOpen());
  }, []);

  useEffect(() => {
    if (props.pageInTransition) {
      gsap.to('.bottom-blue', {
        height: '100vh',
        duration: 1.3,
        ease: 'Power2.easeInOut',
        delay: 0.5
      });
      animateOut();
    }
  }, [props.pageInTransition]);

  return (
    <div className='home-page' ref={pageRef}>
      <Helmet>
        <title>Movie Recommend</title>
        <meta name='description' content='Movie Recommend helps you to find great movies and to watch it on your streaming services like Netflix, Amazon Prime, Hulu itc.' />
      </Helmet>
      <MovieHolder ref={movieHolderRef} />
      <div className='landing-wrapper' ref={wrapperRef}>
        <h1>Discover your new favorite movie</h1>
        <p>
          Movie Recommend is free web app for movie recommendations. You can apply filters or let
          app to choose right movie for you.
        </p>
        <button className='button-primary' onClick={handleOnClick} ref={buttonRef}>
          GET YOUR MOVIE
        </button>
      </div>
      <MovieTape className='movie-tape' ref={movieTapeRef} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    pageInTransition: state.UI.pageInTransition
  };
};

export default connect(mapStateToProps)(Home);
