import React, { useEffect, useRef, useState } from 'react';
import './youtubePlayer.scss';
import gsap from 'gsap';
import { precentOfSize, withAspectRatio } from '../../utils/sizeCalc';
import * as fader from '../../utils/fader';
import { connect } from 'react-redux';

const YoutubePlayer = props => {
  const playerRef = useRef();
  const [width, setWidth] = useState(0);
  let faderRef;

  const handleClose = e => {
    if (!playerRef.current.contains(e.target)) {
      gsap
        .to(playerRef.current, {
          opacity: 0,
          scale: 0.8,
          duration: 0.6,
          ease: 'Power3.easeInOut'
        })
        .then(() => props.setShow(false));
    }
    fader.remove(faderRef);
  };

  useEffect(() => {
    gsap.set(playerRef.current, { visibility: 'visible' });
    gsap.to(playerRef.current, { opacity: 1, duration: 0.8, ease: 'Power3.easeInOut' });
    gsap.from(playerRef.current, { scale: 0.8, duration: 0.8, ease: 'Power3.easeInOut' });

    faderRef = fader.add(document.querySelector('.movie-holder'), 'beforebegin');

    document.addEventListener('click', handleClose);

    return () => {
      document.removeEventListener('click', handleClose);
    };
  }, []);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setWidth(
      props.screen?.tabletLand
        ? precentOfSize(90, window.innerWidth)
        : precentOfSize(60, window.innerWidth)
    );
  };

  return (
    <div className='trailer-holder' ref={playerRef}>
      {props.id && (
        <iframe
          title='trailer'
          className='player-holder'
          id='ytplayer'
          type='text/html'
          width={width}
          height={withAspectRatio(width, 9 / 16)}
          src={'https://www.youtube.com/embed/' + props.id}
          frameBorder='0'
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    screen: state.UI?.screen && state.UI.screen
  };
};

export default connect(mapStateToProps)(YoutubePlayer);
