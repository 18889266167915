import React, { useEffect, useState } from 'react';
import './hamburger.scss';

const Hamburger = props => {
  const [classAppend, setClassAppend] = useState('');

  useEffect(() => {
    setClassAppend(props.toggleMenu ? ' is-active' : '');
  }, [props.toggleMenu]);

  return (
    <div className={'hamburger hamburger--squeeze' + classAppend} onClick={props.onClick}>
      <div className='hamburger-box'>
        <div className='hamburger-inner' />
      </div>
    </div>
  );
};

export default Hamburger;
