import React from 'react';
import './swipeArrowIndicator.scss';

const SwipeArrowIndicator = () => {
  return (
    <div className='center-con'>
      <div id='cta'>
        <span className='arrow primera next '></span>
        <span className='arrow segunda next '></span>
      </div>
    </div>
  );
};

export default SwipeArrowIndicator;
